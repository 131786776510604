import React, { useState, useEffect } from "react";

const SecuritySettings = () => {
  const [configurations, setConfigurations] = useState([]);
  const [newConfigKey, setNewConfigKey] = useState("");
  const [newConfigValue, setNewConfigValue] = useState("");
  const [blockedIPs, setBlockedIPs] = useState([]);
  const [newIP, setNewIP] = useState("");

  // Fetch existing settings and blocked IPs
  useEffect(() => {
    fetchConfigurations();
    fetchBlockedIPs();
  }, []);

  const fetchConfigurations = async () => {
    const response = await fetch("/api/configs");
    const data = await response.json();
    setConfigurations(data);
  };

  const fetchBlockedIPs = async () => {
    const response = await fetch("/api/blocked-ips");
    const data = await response.json();
    setBlockedIPs(data);
  };

  const handleAddConfig = async () => {
    if (newConfigKey && newConfigValue) {
      const response = await fetch("/api/configs", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ key: newConfigKey, value: newConfigValue }),
      });

      if (response.ok) {
        fetchConfigurations();
        setNewConfigKey("");
        setNewConfigValue("");
      }
    }
  };

  const handleRemoveConfig = async (key) => {
    await fetch(`/api/configs/${key}`, { method: "DELETE" });
    fetchConfigurations();
  };

  const handleBlockIP = async () => {
    if (newIP) {
      await fetch("/api/blocked-ips", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ip: newIP }),
      });

      fetchBlockedIPs();
      setNewIP("");
    }
  };

  const handleUnblockIP = async (ip) => {
    await fetch(`/api/blocked-ips/${ip}`, { method: "DELETE" });
    fetchBlockedIPs();
  };

  return (
    <section className="p-6 bg-gray-50">
      <h1 className="text-2xl font-bold mb-4">CRM Security Settings</h1>

      {/* Configuration Section */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-2">Configuration Keys</h2>
        <div className="flex space-x-2 mb-4">
          <input
            type="text"
            placeholder="Config Key"
            value={newConfigKey}
            onChange={(e) => setNewConfigKey(e.target.value)}
            className="p-2 border rounded"
          />
          <input
            type="text"
            placeholder="Config Value"
            value={newConfigValue}
            onChange={(e) => setNewConfigValue(e.target.value)}
            className="p-2 border rounded"
          />
          <button
            onClick={handleAddConfig}
            className="p-2 bg-blue-500 text-white rounded"
          >
            Add
          </button>
        </div>

        <ul>
          {configurations.map((config) => (
            <li
              key={config.key}
              className="flex justify-between p-2 bg-white mb-2"
            >
              <span>
                <strong>{config.key}:</strong> {config.value}
              </span>
              <button
                onClick={() => handleRemoveConfig(config.key)}
                className="text-red-600"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* IP Blocking Section */}
      <div>
        <h2 className="text-lg font-semibold mb-2">Blocked IPs</h2>
        <div className="flex space-x-2 mb-4">
          <input
            type="text"
            placeholder="IP Address"
            value={newIP}
            onChange={(e) => setNewIP(e.target.value)}
            className="p-2 border rounded"
          />
          <button
            onClick={handleBlockIP}
            className="p-2 bg-red-500 text-white rounded"
          >
            Block IP
          </button>
        </div>

        <ul>
          {blockedIPs.map((ip) => (
            <li key={ip} className="flex justify-between p-2 bg-white mb-2">
              <span>{ip}</span>
              <button
                onClick={() => handleUnblockIP(ip)}
                className="text-green-600"
              >
                Unblock
              </button>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SecuritySettings;
