import React, { useEffect, useState, useRef } from "react";
import { getUserData } from "../../helpers/utils";
import "react-data-table-component-extensions/dist/index.css";
import Adduser from "../popup/AddUserSuperAdmin";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import EditUser from "../popup/EditUserSuperAdmin";
import { usePopup } from "../Common/PopupContext";
import CustomDataTable from "../Common/Customsdatatable";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
import Cookies from "js-cookie";
import axios from "axios";
import { encryptData } from "../../helpers/encryption";
import api from "../../Api";
const domainpath = process.env.REACT_APP_DOMAIN;
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const ManageUser = () => {
  const User = getUserData();
  const { openPopup } = usePopup();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const toastRef = useRef();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);

  const history = useHistory();
  // const [popupComponent, setPopupComponent] = useState(null);
  const handleLoginClick = () => {
    const popup = <Adduser onClose={() => handleClosePopup("Popup closed")} />;
    //setPopupComponent(popup);
    openPopup(popup);
  };
  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };
  const handleSearchChange = async (e) => {
    e.preventDefault();
    const search = e.target.value;
    setSearchTerm(search);

    if (!search.trim()) {
      setFilteredUsers([]); // Clear results if search is empty
      return;
    }

    try {
      const response = await axios.get(`${domainPath}/api/users/search-users`, {
        params: {
          keyword: search, // Pass the search keyword dynamically
        },
      });
      const { data } = response.data;
      const userss = data.map((user) => ({
        ...user,
        canEdit: true, // Set default to true; adjust based on your requirements
        canDelete: true, // Set default to true; adjust based on your requirements
        canView: true, // Set default to true; adjust based on your requirements
        canShare: false, // Set default to true; adjust based on your requirements
      }));
      const totalCount = response?.totalPages || 0;
      setdata(userss);
      setFilteredData(userss);
      setTotalRows(response.data.pagination.totalUsers || 0);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handlePerRowsChange = async (newPerPage) => {
    if (newPerPage !== perPage) {
      setPerPage(newPerPage);
      setPage(1); // Reset to the first page
    }
  };
  const handleDelete = async (jobId) => {
    const response = await api.deleteStaff(`${jobId}`);
    //console.log(response);

    if (response.status != 500) {
      toastRef.current.addToast(response.message, "sucess");
      setRender(render + 1);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the User!",
        "error"
      );
    }
  };
  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  const handleEditUser = (id) => {
    const popup = (
      <EditUser id={id} onClose={() => handleClosePopup("Popup closed")} />
    );
    //setPopupComponent(popup);
    openPopup(popup);
  };

  const handleSecertLogin = async (id) => {
    setLoading(true);
    try {
      const userData = {
        userId: id,
      };
      const response = await api.SeceretLogin(userData);
      //console.log(response);

      if (response.status !== 400) {
        toastRef.current.addToast("Login Successfully", "success");
        // Destructure tokens and user details from API response
        const { accessToken, refreshToken } = response?.user;
        const encryptedUserData = encryptData(response.user);

        // Get the existing tokens and user data from localStorage and cookies
        const existingAccessToken =
          localStorage.getItem("accessToken") || Cookies.get("accessToken");
        const existingRefreshToken =
          localStorage.getItem("refreshToken") || Cookies.get("refreshToken");
        const existingUser =
          localStorage.getItem("user") || Cookies.get("user");

        // Rename and store existing values as admin keys in localStorage and cookies
        if (existingAccessToken) {
          localStorage.setItem("adminaccessToken", existingAccessToken);
          Cookies.set("adminaccessToken", existingAccessToken);
        }
        if (existingRefreshToken) {
          localStorage.setItem("adminrefreshToken", existingRefreshToken);
          Cookies.set("adminrefreshToken", existingRefreshToken);
        }
        if (existingUser) {
          localStorage.setItem("adminuser", existingUser);
          Cookies.set("adminuser", existingUser);
        }

        // Log for debugging (optional)
        // console.log("Existing tokens and user renamed as admin keys", {
        //   adminAccessToken: localStorage.getItem("adminaccessToken"),
        //   adminRefreshToken: localStorage.getItem("adminrefreshToken"),
        //   adminUser: localStorage.getItem("adminuser"),
        // });

        // Set new tokens and user data from API response in localStorage and cookies
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("user", encryptedUserData);

        // If keepMeLoggedIn is true, persist in localStorage; otherwise, use sessionStorage

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("user", encryptedUserData);

        // Redirect to the appropriate URL
        setTimeout(() => {
          setLoading(false);
          window.location.href = "/dashboard";
        }, 2000);
      } else if (response.status === 400) {
        // Handle login failure
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );

        toastRef.current.addToast(
          response.data.msg || "An error occurred",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors
      console.error(error);

      toastRef.current.addToast(
        error.response?.data?.msg || "An error occurred",
        "error"
      );
      setLoading(false);
    }
  };
  const status = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "InActive" },
    { value: "block", label: "Block" },
  ];
  const handleUpdateUserStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    var formdata = {
      status: e.target.value,
    };
    try {
      const res = await api.UpdateUserStatus(id, formdata);
      if (res.status === 200) {
        toastRef.current.addToast(res.message, "success");
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  const columns = [
    {
      name: "",
      selector: (row) => (
        <>
          <img
            src={
              row.profileimg && row.profileimg !== "NA" && row.profileimg !== ""
                ? row.profileimg.startsWith("https://")
                  ? row.profileimg
                  : `${domainPath}/uploads/${row.profileimg
                      .replace("/uploads/uploads/", "/uploads/")
                      .replace(/^\/uploads\//, "")}`
                : `${domainPath}/uploads/deafultman.png`
            }
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = `${domainPath}/uploads/deafultman.png`;
            }}
            alt="Profile"
            className="w-full h-full rounded-full"
          />
        </>
      ),
      width: "10%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <>
          {" "}
          <div
            className="flex items-center hover:underline"
            style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
          >
            <span
              // style={{ marginLeft: "10px" }}
              className="cursor-pointer"
              onClick={(e) => handleSecertLogin(row?._id)}
            >
              {row?.name}
            </span>
          </div>
          <span>{row?.email}</span>
          <br></br>
          <span>{row?.phone}</span>
        </>
      ),
      sortable: true,
      width: "35%",
    },

    {
      name: "Role",
      selector: (row) => row?.role,
      sortable: true,
      width: "15%",
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className="relative job-search-label-section singleselect2 statusenroll"
          style={{ width: "100%", textAlign: "center" }}
        >
          <Select2
            data={status.map((type) => ({
              id: type.value,
              text: type.label,
            }))}
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            value={row.status}
            onSelect={(e) => handleUpdateUserStatus(e, row._id)}
            options={{
              placeholder: "Select Status",
              width: "100%",
              theme: "classic",
            }}
          />
        </div>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2 w-full item-center justify-center">
          <button
            className="manage-edit-btn text-white px-4 py-2"
            // onClick={(e) => handleEditUser(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-edit-2 "
            >
              <g>
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </g>
            </svg>
          </button>
          <span
            className="manage-delete-btn text-white px-3 py-2 "
            onClick={(e) => handleDelete(row._id)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 "
              color="red"
            >
              <g>
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </g>
            </svg>
          </span>
          <button
            className="manage-secrate-btn text-white px-3 py-2 "
            onClick={(e) => handleSecertLogin(row._id)}
          >
            <FeatherIcon
              icon="eye"
              onClick={(e) => handleSecertLogin(row?._id)}
              className="cursor-pointer"
            />
          </button>
        </div>
      ),
      width: "30%",
    },
  ];

  useEffect(() => {
    async function getusers() {
      try {
        const response = await api.getUsers();
        console.log(response);
        if (response.status !== 400) {
          const userss = (response?.users || response?.users).map((user) => ({
            ...user,
            canEdit: true, // Set default to true; adjust based on your requirements
            canDelete: true, // Set default to true; adjust based on your requirements
            canView: true, // Set default to true; adjust based on your requirements
            canShare: false, // Set default to true; adjust based on your requirements
          }));
          const totalCount = response?.totalPages || 0;
          setdata(userss);
          setFilteredData(userss);
          setTotalRows(totalCount);
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, [render]);

  const tableData = {
    columns,
    data,
  };
  const flattenUserData = (user) => {
    return {
      "User ID": user._id,
      Name: user.name,
      Email: user.email,
      Role: user.role,
      Phone: user.phone || "N/A",
      "Profile Image": user.profileimg || "N/A",
      "Saved Jobs Count": user.savedJobs?.length || 0,
      Status: user.status || "N/A",
      "Is Verified": user.isVerified ? "Yes" : "No",
      "Created At": user.createdAt?.split("T")[0] || "N/A",
      "Updated At": user.updatedAt?.split("T")[0] || "N/A",
    };
  };
  const handleDownload = () => {
    try {
      // Flatten all user data
      const flattenedData = filteredData.map(flattenUserData);
      console.log("Flattened Data:", flattenedData);

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(flattenedData);

      // Optional: Add column widths for better readability
      ws["!cols"] = [
        { wch: 25 }, // User ID
        { wch: 20 }, // Name
        { wch: 30 }, // Email
        { wch: 15 }, // Role
        { wch: 20 }, // Phone
        { wch: 40 }, // Profile Image
        { wch: 15 }, // Saved Jobs Count
        { wch: 10 }, // Status
        { wch: 10 }, // Is Verified
        { wch: 15 }, // Created At
        { wch: 15 }, // Updated At
      ];

      // Create a workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "User Data");

      // Write and download the file
      XLSX.writeFile(wb, "user_data.xlsx");
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };
  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between ">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
            Manage User
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={handleLoginClick}
          >
            Add User
          </button>
        </div>

        <div className="flex gap-2 mb-4 justify-between">
          {" "}
          <input
            type="text"
            placeholder="Search Users"
            value={searchTerm} // Reflect the state
            onChange={(e) => setSearchTerm(e.target.value)} // Immediate update
            onKeyUp={handleSearchChange} // API call on keyup
            style={{ borderRadius: "50px", padding: "10px 20px" }}
            className="border rounded p-2 mb-4"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm("")} // Clear the search term
              title="Clear Search"
              style={{
                padding: "10px 20px",
                marginLeft: "20px",
              }}
              className="joblist-cardbtnapply"
            >
              Clear
            </button>
          )}
          <div>
            {" "}
            <div className="flex gap-5 items-center">
              <div className="flex gap-2">
                <div>
                  {" "}
                  <div
                    className="custom-print-style"
                    //onClick={handlePrint}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.4999 6.41667H5.49992V2.75H16.4999V6.41667ZM16.4999 11.4583C16.7596 11.4583 16.9775 11.3703 17.1535 11.1943C17.3295 11.0183 17.4172 10.8008 17.4166 10.5417C17.416 10.2826 17.328 10.065 17.1526 9.889C16.9772 9.713 16.7596 9.625 16.4999 9.625C16.2402 9.625 16.0226 9.713 15.8473 9.889C15.6719 10.065 15.5839 10.2826 15.5833 10.5417C15.5826 10.8008 15.6706 11.0186 15.8473 11.1953C16.0239 11.3719 16.2414 11.4596 16.4999 11.4583ZM14.6666 17.4167V13.75H7.33325V17.4167H14.6666ZM16.4999 19.25H5.49992V15.5833H1.83325V10.0833C1.83325 9.30417 2.10061 8.65119 2.63534 8.12442C3.17006 7.59764 3.81936 7.33394 4.58325 7.33333H17.4166C18.1958 7.33333 18.849 7.59703 19.3764 8.12442C19.9038 8.65181 20.1672 9.30478 20.1666 10.0833V15.5833H16.4999V19.25Z"
                        fill="#FFA500"
                      />
                    </svg>
                  </div>
                  Print
                </div>
                <div>
                  <div className="flex justify-center">
                    {" "}
                    <div
                      className="custom-download-style"
                      onClick={handleDownload}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.1078 7.24695C8.23671 7.37569 8.41145 7.44801 8.59363 7.44801C8.77582 7.44801 8.95056 7.37569 9.07947 7.24695L10.6561 5.67028V13.1778C10.6561 13.3601 10.7286 13.535 10.8575 13.6639C10.9864 13.7928 11.1613 13.8653 11.3436 13.8653C11.526 13.8653 11.7008 13.7928 11.8298 13.6639C11.9587 13.535 12.0311 13.3601 12.0311 13.1778V5.67028L13.6078 7.24695C13.6707 7.31449 13.7466 7.36867 13.831 7.40625C13.9153 7.44382 14.0063 7.46403 14.0987 7.46566C14.191 7.46729 14.2827 7.4503 14.3683 7.41573C14.4539 7.38115 14.5316 7.32968 14.5969 7.2644C14.6622 7.19912 14.7137 7.12135 14.7482 7.03575C14.7828 6.95014 14.7998 6.85845 14.7982 6.76613C14.7965 6.67382 14.7763 6.58279 14.7388 6.49845C14.7012 6.41412 14.647 6.33822 14.5795 6.27528L11.8295 3.52528C11.7006 3.39653 11.5258 3.32422 11.3436 3.32422C11.1614 3.32422 10.9867 3.39653 10.8578 3.52528L8.1078 6.27528C7.97905 6.40419 7.90674 6.57893 7.90674 6.76111C7.90674 6.9433 7.97905 7.11804 8.1078 7.24695Z"
                          fill="#003366"
                        />
                        <path
                          d="M11.3438 18.6771C13.2888 18.6771 15.154 17.9045 16.5293 16.5292C17.9045 15.1539 18.6772 13.2887 18.6772 11.3438H15.2397C14.3752 11.3438 13.9435 11.3438 13.6749 11.6123C13.4063 11.8809 13.4063 12.3127 13.4063 13.1771C13.4063 13.7241 13.189 14.2487 12.8022 14.6355C12.4154 15.0223 11.8908 15.2396 11.3438 15.2396C10.7968 15.2396 10.2722 15.0223 9.88542 14.6355C9.49863 14.2487 9.28133 13.7241 9.28133 13.1771C9.28133 12.3127 9.28133 11.8809 9.01275 11.6123C8.74416 11.3438 8.31241 11.3438 7.448 11.3438H4.0105C4.0105 13.2887 4.78312 15.1539 6.15838 16.5292C7.53365 17.9045 9.39891 18.6771 11.3438 18.6771Z"
                          fill="#003366"
                        />
                      </svg>
                    </div>
                  </div>
                  Export
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDataTable
          columns={columns}
          data={filteredData}
          totalRows={totalRows}
          rowsPerPageOptions={[10, 25, 50]}
          defaultRowsPerPage={perPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handlePerRowsChange}
          currentPage={page}
          handleEdit={handleEditUser}
          handleView={handleSecertLogin}
          handleDelete={handleDelete}
        />
        {/* <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions> */}
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageUser;
