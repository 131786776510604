import React, { useEffect, useState, useRef } from "react";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import { usePopup } from "../Common/PopupContext";
import FeatherIcon from "feather-icons-react";
import { useLocation, useHistory } from "react-router-dom";
import Login from "../Website/Login";
import VisitorTracker from "../Common/VisitorTracker.js";
import { getUserData } from "../../helpers/utils";
import { reportReasons } from "../../helpers/Object";
import Applyjobswithout from "../popup/ApplyjobwithoutLogin";
import ApplyJobs from "../popup/Applyjob";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;
const logoimage = clientdomainpath + "/staticlogo.png";
const ReportJob = ({ onClose, JobId }) => {
  const User = getUserData();
  const jobId = JobId;
  const toastRef = useRef();
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCheckboxChange = (event) => {
    const reason = event.target.value;
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    try {
      const candidateId = User._id;
      const reportdata = {
        jobId,
        candidateId,
        reasons: selectedReasons,
        details,
      };
      //console.log(reportdata);

      const response = await api.reportjob(reportdata);

      if (response.status == 201) {
        toastRef.current.addToast("Job Reported Successfully", "success");
        setLoading(false);
        setTimeout(() => {
          onClose("");
          window.location.reload();
        }, 800);
      } else if (response.status == 500) {
        console.error(
          "Login failed: Unexpected response status",
          response.status
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toastRef.current.addToast(error.response.data.message, "error");

      setLoading(false);
    }
  };
  return (
    <div className="w-4/5 flex justify-center h-screen  login_inform-inner m-auto p-20">
      <div className="flex">
        <div className="form_upto m-3 loginDiv reportjob-width" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4 overflow-scroll  h-5/6 overflow-x-hidden custom-scrollbar">
            <div className="container mx-auto quickview-page ">
              <form
                onSubmit={handleSubmit}
                className="animated-form report-job-form"
              >
                <h2
                  className="font-semibold  text-base"
                  style={{ color: "#003366" }}
                >
                  Report Job
                </h2>
                <div className="report-reasons">
                  {reportReasons.map((reason, index) => (
                    <div key={index} className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          value={reason}
                          checked={selectedReasons.includes(reason)}
                          onChange={handleCheckboxChange}
                        />
                        {reason}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="form-group  col-span-2">
                  <textarea
                    id="description"
                    name="description"
                    placeholder=""
                    defaultValue={details}
                    onChange={(e) => setDetails(e.target.value)}
                    className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer"
                  />
                  <label className="form-input-label absolute top-2 left-4 transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600">
                    Additional Details:
                  </label>
                </div>
                <button className="mt-2" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          className="  text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-8	 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};
const SingleJob = () => {
  const toastRef = useRef();
  const history = useHistory();
  const [jobs, setjobs] = useState("");
  const [errordata, seterrordata] = useState(false);
  const [JobData, setJobData] = useState(null);
  const [error, setError] = useState("");
  let data = useLocation();
  const segments = data.pathname.split("/");
  const { openPopup } = usePopup();
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const statusMessages = {
    Closed: `Thank you for your interest! 
           This position is now filled, but please check our job portal for other exciting opportunities. Best of luck!`,
    Hold: `This job is currently paused while we work behind the scenes! 
         It’s temporarily unavailable for further action, but we’re on it. For updates or questions, feel free to reach out to our support team. Thanks for your patience!`,
    Disabled: `This job posting is currently disabled and is not accepting applications at this time. 
             Please check back later or explore other positions available on our portal.`,
    Draft: `This job posting is currently in draft mode and is not visible to applicants. 
          Please finalize the details and publish it to make it available for applications.`,
    Block: `This job posting has been blocked due to policy violations or other issues. 
          It is not accessible at this time. For more information or to resolve the issue, please contact our support team.`,
  };

  useEffect(() => {
    setLoading(true);

    async function getJob() {
      try {
        const response = await api.getSingleJob(segments[2]);
        // console.log("API Response:", response);

        if (response?.status === 200 && response?.job) {
          const job = response.job;
          const jobStatus = job.status;
          //console.log(jobStatus);

          const message =
            statusMessages[jobStatus] || "Job status not available.";

          // Parse pay values only if valid
          if (job.pay) {
            job.pay.minimum = !isNaN(job.pay.minimum)
              ? parseFloat(job.pay.minimum).toFixed(2)
              : "N/A";
            job.pay.maximum = !isNaN(job.pay.maximum)
              ? parseFloat(job.pay.maximum).toFixed(2)
              : "N/A";
          }

          // Handle job visibility based on status and user role
          if (
            ["Closed", "Hold", "Disabled", "Block", "Draft"].includes(
              jobStatus
            ) &&
            (User?.role === "applicant" || !User)
          ) {
            console.log(
              "Restricted for applicant or unauthenticated user:",
              jobStatus
            );
            setLoading(false);
            setjobs("");
            setError(message);
            return;
          } else if (
            ["Closed", "Hold", "Disabled", "Block", "Draft"].includes(
              jobStatus
            ) &&
            User?.role === "employer" &&
            User?._id !== job.employerid
          ) {
            // Restricted for everyone except the job's employer
            setLoading(false);
            setjobs("");
            setError(message);
            return;
          } else {
            // console.log("Job is accessible:", job);
            setLoading(false);
            setjobs(job);
            setJobData(response);

            // Construct JSON-LD schema
            const jsonLd = {
              "@context": "http://schema.org",
              "@type": "JobPosting",
              title: job.title || "Job Title Not Available",
              description: job.description || "Description Not Available",
              datePosted: job.postedDate?.split("T")[0] || "N/A",
              validThrough: job.applicationDeadline?.split("T")[0] || "N/A",
              employmentType: job.jobType?.join(", ") || "N/A",
              hiringOrganization: {
                "@type": "Organization",
                name: job.company?.name || "Company Name Not Available",
                sameAs: "https://www.yourcompany.com",
                logo: job.company?.logo || "",
              },
              jobLocation: {
                "@type": "Place",
                address: {
                  "@type": "PostalAddress",
                  streetAddress: job.location?.address || "N/A",
                  addressLocality: job.location?.city || "N/A",
                  addressRegion: job.location?.area || "N/A",
                  postalCode: job.location?.pincode || "N/A",
                  addressCountry: "UK",
                },
              },
              baseSalary: {
                "@type": "MonetaryAmount",
                currency: "USD",
                value: {
                  "@type": "QuantitativeValue",
                  minValue: job.pay.minimum || 0,
                  maxValue: job.pay.maximum || 0,
                  unitText: "YEAR",
                },
              },
            };

            // Append JSON-LD schema to the document head
            const existingScript = document.getElementById(
              "job-posting-json-ld"
            );
            if (existingScript) {
              existingScript.remove();
            }
            const script = document.createElement("script");
            script.id = "job-posting-json-ld";
            script.type = "application/ld+json";
            script.text = JSON.stringify(jsonLd);
            document.head.appendChild(script);
          }

          // Job is accessible, set job data
        } else if (response?.status === 400) {
          // console.warn("Invalid Job ID:", response);
          seterrordata(true);
          setLoading(false);
          setError("Invalid job ID.");
        } else {
          // console.warn("Unexpected API response:", response);
          seterrordata(true);
          setLoading(false);
          setError(
            `Oops!
           It looks like the Job ID you entered is invalid. Please double-check and try again. If the issue persists, reach out to our support team for further assistance.`
          );
        }
      } catch (error) {
        console.error("API Fetch Error:", error);
        setLoading(false);
        setError(
          error?.response?.data?.message ||
            `Oops!
           It looks like the Job ID you entered is invalid. Please double-check and try again. If the issue persists, reach out to our support team for further assistance.`
        );
        seterrordata(true);
      }
    }

    getJob();

    if (segments[3] !== undefined && segments[3] !== "") {
      const popup = (
        <Applyjobswithout
          onClose={() => handleClosePopup("Popup closed")}
          JobId={segments}
        />
      );
      openPopup(popup);
    }
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   async function getjob() {
  //     try {
  //       const response = await api.getSingleJob(segments[2]);
  //       console.log(response, "response");

  //       if (response?.status === 200 && response?.job) {
  //         seterrordata(false);
  //         const message = statusMessages[response.job.status];
  //         if (response.job && response.job.pay) {
  //           response.job.pay.minimum = parseFloat(
  //             response.job.pay.minimum
  //           ).toFixed(2);
  //           response.job.pay.maximum = parseFloat(
  //             response.job.pay.maximum
  //           ).toFixed(2);
  //         }

  //         if (
  //           response?.job?.status === "Closed" &&
  //           (User === null || User?.role === "applicant")
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else if (
  //           response?.job?.status === "Closed" &&
  //           (User?.role === "employer" ||
  //             User?._id === response?.job.employerid)
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else if (
  //           response?.job?.status === "Hold" &&
  //           (User?.role === "employer" ||
  //             User?._id === response?.job.employerid)
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else if (
  //           response?.job?.status === "Hold" &&
  //           (User === null || User?.role === "applicant")
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else if (
  //           response?.job?.status === "Disabled" &&
  //           (User?.role === "employer" ||
  //             User?._id === response?.job.employerid)
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else if (
  //           response?.job?.status === "Disabled" &&
  //           (User === null || User?.role === "applicant")
  //         ) {
  //           setLoading(false);
  //           setjobs(""); // Set jobs to null if closed
  //           setError(message);
  //         } else {
  //           // Proceed with setting the job details if it's not closed
  //           setLoading(false);
  //           setjobs(response.job);
  //           setJobData(response);

  //           setJobData(response);
  //           const jobData = response.job;
  //           const jsonLd = {
  //             "@context": "http://schema.org",
  //             "@type": "JobPosting",
  //             title: jobData.title,
  //             description: jobData.description,
  //             datePosted: jobData.postedDate.split("T")[0],
  //             validThrough: jobData.applicationDeadline.split("T")[0],
  //             employmentType: jobData.jobType.join(", "),
  //             hiringOrganization: {
  //               "@type": "Organization",
  //               name: jobData.company.name,
  //               sameAs: "https://www.yourcompany.com",
  //               logo: jobData.company.logo,
  //             },
  //             jobLocation: {
  //               "@type": "Place",
  //               address: {
  //                 "@type": "PostalAddress",
  //                 streetAddress: jobData.location.address,
  //                 addressLocality: jobData.location.city,
  //                 addressRegion: jobData.location.area,
  //                 postalCode: jobData.location.pincode,
  //                 addressCountry: "UK",
  //               },
  //             },
  //             baseSalary: {
  //               "@type": "MonetaryAmount",
  //               currency: "USD",
  //               value: {
  //                 "@type": "QuantitativeValue",
  //                 minValue: jobData.pay.minimum,
  //                 maxValue: jobData.pay.maximum,
  //                 unitText: "YEAR",
  //               },
  //             },
  //           };
  //           const existingScript = document.getElementById(
  //             "job-posting-json-ld"
  //           );
  //           if (existingScript) {
  //             existingScript.remove();
  //           }
  //           const script = document.createElement("script");
  //           script.id = "job-posting-json-ld";
  //           script.type = "application/ld+json";
  //           script.text = JSON.stringify(jsonLd);
  //           document.head.appendChild(script);
  //           // Additional logic here
  //         }
  //       } else if (response.status === 400) {
  //         seterrordata(true);
  //         setLoading(false);
  //         setError("Invailid job id");
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //       const errorMessage =
  //         error?.response?.data?.message ||
  //         `Oops!
  //          It looks like the Job ID you entered is invalid. Please double-check and try again. If the issue persists, reach out to our support team for further assistance.`;

  //       setError(errorMessage);
  //       seterrordata(true);
  //     }
  //   }
  //   getjob();
  //   if (segments[3] !== undefined && segments[3] !== "") {
  //     const popup = (
  //       <Applyjobswithout
  //         onClose={() => handleClosePopup("Popup closed")}
  //         JobId={segments}
  //       />
  //     );
  //     openPopup(popup);
  //   }
  // }, []);
  const handleClosePopup = (message) => {
    openPopup();
  };

  const handleClickReport = () => {
    const popup = (
      <ReportJob
        onClose={() => handleClosePopup("Popup closed")}
        JobId={segments[2]}
      />
    );
    openPopup(popup);
  };
  const styles = {
    table: {
      width: "100%",
      marginBottom: "20px",
      borderRadius: "5px",
      border: "1px solid #C6C6C6",
    },
    rowstyle: {
      borderBottom: "1px solid #C6C6C6",
    },
    header: {
      borderRight: "1px solid #C6C6C6",
      padding: "8px 20px",
      color: "#003366",
      backgroundColor: "#F1F4F7",
      fontWeight: "600",
      textAlign: "left",
    },
    categoryCell: {
      borderRight: "1px solid #C6C6C6",
      padding: "8px",
      fontWeight: "bold",
      verticalAlign: "top",
      backgroundColor: "#f9f9f9",
      // width: "20%",
    },
    cell: {
      borderRight: "1px solid #C6C6C6",
      padding: "8px",
      // width: "30%",
      verticalAlign: "top",
      position: "relative",
    },
    deleteQualificationCell: {
      padding: "10px",
      verticalAlign: "middle",
      textAlign: "center",
      position: "absolute",
      right: "-6%",
      bottom: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      padding: "5px",
      cursor: "text",
      color: "#3E3E3E",
      fontWeight: "500",
      fontSize: "14px",
    },
    text2: {
      padding: "5px",
      cursor: "text",
      color: "#3E3E3E",
      fontWeight: "400",
      fontSize: "14px",
    },
    borderButtons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    iconButton: {
      border: "none",
      cursor: "pointer",
      background: "#fff",
    },
    crossWithTd: {
      display: "flex",
      alignItems: "center",
      verticalAlign: "top",
      justifyContent: "space-between",
      padding: "8px",
    },
    addRowIconWrapper: {
      position: "absolute",
      bottom: "-12px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      zIndex: "999",
    },
    addRowIcon: {
      background: "none",
      border: "none",
      cursor: "pointer",
      padding: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      background: "green",
      borderRadius: "30px",
    },
    addButton: {
      padding: "5px 10px",
      border: "none",
      borderRadius: "40px",
      background: "rgb(0 51 102)",
      color: "rgb(255, 255, 255)",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
  };
  function decodeHtmlEntities(text) {
    const element = document.createElement("div");
    if (text) {
      // Set the text with potential HTML entities inside the element
      element.innerHTML = text;
      // Extract and return the plain text (decoded)
      return element.textContent || element.innerText || "";
    }
    return text; // Return original text if empty or undefined
  }
  const jobApply = (jobid) => {
    const popup = (
      <ApplyJobs
        onClose={() => handleClosePopup("Popup closed")}
        JobId={jobid}
      />
    );
    openPopup(popup);
  };

  const handleToggleSaveJob = async (e, jobid) => {
    e.preventDefault();
    if (User !== "" && User != null) {
      const userid = User._id;
      setLoading(true);

      try {
        let response;
        if (JobData?.saved === "Not saved") {
          response = await api.saveJob(userid, jobid);
        } else {
          response = await api.unsavedJOb(userid, jobid);
        }

        //console.log(response);

        if (response.status === 200) {
          toastRef.current.addToast(response.message, "success");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toastRef.current.addToast(response.data.message, "error");
        }
      } catch (error) {
        toastRef.current.addToast(error.response?.data?.message, "error");
      } finally {
        setLoading(false);
      }
    } else {
      const popup = (
        <Login
          onClose={() => handleClosePopup("Popup closed")}
          savejobid={jobid}
          redirect_url={`/jobs`}
        />
      );
      openPopup(popup);
    }
  };
  const handleShare = async (e) => {
    e.preventDefault();

    // Extract details
    const jobId = jobs?._id; // Assuming you have this value from the `jobs` object
    const jobTitle = jobs?.title; // Replace with actual job title if dynamic
    const companyName = jobs?.company?.name || ""; // Replace with actual company name if dynamic

    // Construct the URL
    const shareUrl = `${clientdomainpath}/jobs/${jobId}?${new URLSearchParams({
      job: jobTitle,
      company: companyName,
    }).toString()}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: `Check out this Job: ${jobs?.title}`,
          url: shareUrl,
        });
        // console.log("Profile shared successfully");
      } catch (error) {
        if (error.name === "AbortError") {
          console.warn("User aborted the share action.");
        } else {
          console.error("Error sharing profile:", error);
        }
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert("Profile link copied to clipboard!");
      } catch (clipboardError) {
        console.error("Failed to copy to clipboard:", clipboardError);
      }
    }
  };

  return (
    <>
      <div className="hrp-main-section hrp-single-job-page">
        <div className="hrp-section-1 hrp-container">
          {jobs != "" && errordata === false ? (
            <div className="hrp-sub-section">
              <div
                className="container mx-auto my-10"
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <div className="main-JobPosting-div py-10">
                  <div className="hrp-sub-section-1 lg:flex w-full JobPosting-inner-div items-center">
                    <div className="py-10 flex justify-center">
                      <img
                        src={
                          jobs.company.logo.startsWith("http")
                            ? jobs.company.logo
                            : jobs.company.logo.includes("uploads")
                            ? `${domainpath}${jobs.company.logo}`
                            : `${domainpath}/uploads/${jobs.company.logo}`
                        }
                        alt={`${jobs.company.name} Logo`}
                        onClick={() =>
                          history.push(`/companies/${jobs.company.name}`)
                        }
                        style={{ width: "120px" }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = logoimage; // Replace with a default logo
                        }}
                      />
                    </div>
                    <div className="w-full" style={{ padding: "10px 23px" }}>
                      <div className="md:flex">
                        <div className="md:w-2/5 hrp-border-single">
                          <div className="md:p-4">
                            <h2
                              className="font-semibold text-xl"
                              style={{ color: "#003366" }}
                              onClick={() =>
                                history.push(`/companies/${jobs.company.name}`)
                              }
                            >
                              {jobs?.company?.name ||
                                "Company Name Not Available"}
                            </h2>
                            {jobs?.location &&
                            (jobs.location.city ||
                              jobs.location.area ||
                              jobs.location.pincode ||
                              jobs.location.address) ? (
                              <div className="location-inner">
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="red"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00014 14.1257L8.4809 13.584C9.02643 12.9592 9.51709 12.3665 9.95366 11.8027L10.314 11.3272C11.8188 9.2998 12.5716 7.69066 12.5716 6.50133C12.5716 3.96266 10.5251 1.90475 8.00014 1.90475C5.47519 1.90475 3.42871 3.96266 3.42871 6.50133C3.42871 7.69066 4.18147 9.2998 5.68623 11.3272L6.04662 11.8027C6.66943 12.6007 7.32103 13.375 8.00014 14.1257Z"
                                      stroke="#3E3E3E"
                                      strokeWidth="1.14286"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M8.00047 8.38093C9.05244 8.38093 9.90523 7.52814 9.90523 6.47617C9.90523 5.4242 9.05244 4.57141 8.00047 4.57141C6.94849 4.57141 6.0957 5.4242 6.0957 6.47617C6.0957 7.52814 6.94849 8.38093 8.00047 8.38093Z"
                                      stroke="#3E3E3E"
                                      strokeWidth="1.14286"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>

                                <h1>
                                  {jobs?.location?.address !== ""
                                    ? jobs?.location?.address
                                    : jobs?.location?.city +
                                        " " +
                                        jobs?.location?.pincode || ""}
                                </h1>
                              </div>
                            ) : null}
                            {jobs?.pay &&
                            jobs.pay.rateType &&
                            jobs.pay.minimum !== "NaN" &&
                            jobs.pay.minimum !== "Not specified" &&
                            jobs.pay.maximum !== "NaN" ? (
                              <>
                                <div className="hrp-sub-heading">
                                  <h1
                                    className="font-semibold "
                                    style={{ color: "#003366" }}
                                  >
                                    Salary
                                  </h1>
                                </div>
                                <div className="salery-inner">
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M13.9493 7.83333H12.1533C11.204 7.83333 10.4993 8.53933 10.4993 9.33333C10.4993 10.1273 11.204 10.8333 12.1527 10.8333H13.9647C14.102 10.8247 14.1613 10.732 14.166 10.676V7.99066C14.1613 7.93466 14.102 7.842 13.9647 7.834L13.9493 7.83333ZM13.9 6.83333C13.944 6.83333 13.9867 6.83333 14.0267 6.83599C14.6067 6.87133 15.1207 7.30933 15.164 7.92133C15.1667 7.96133 15.1667 8.00466 15.1667 8.04466V10.622C15.1667 10.662 15.1667 10.7053 15.164 10.7453C15.1207 11.3573 14.6067 11.7953 14.026 11.8313C13.9867 11.8333 13.944 11.8333 13.8993 11.8333H12.154C10.724 11.8333 9.5 10.748 9.5 9.33333C9.5 7.91866 10.724 6.83333 12.1533 6.83333H13.8887H13.9Z"
                                      fill="#4B8700"
                                    />
                                    <path
                                      d="M12.6663 9.33334C12.6663 9.51015 12.5961 9.67972 12.4711 9.80474C12.3461 9.92977 12.1765 10 11.9997 10C11.8229 10 11.6533 9.92977 11.5283 9.80474C11.4032 9.67972 11.333 9.51015 11.333 9.33334C11.333 9.15653 11.4032 8.98696 11.5283 8.86193C11.6533 8.73691 11.8229 8.66667 11.9997 8.66667C12.1765 8.66667 12.3461 8.73691 12.4711 8.86193C12.5961 8.98696 12.6663 9.15653 12.6663 9.33334Z"
                                      fill="#4B8700"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M13.8997 6.83334C14.0909 6.82611 14.2813 6.86259 14.4563 6.94C14.385 5.86934 14.1883 5.044 13.5723 4.42734C13.073 3.92867 12.4403 3.70734 11.659 3.602L11.631 3.59867C11.624 3.59338 11.6169 3.58827 11.6097 3.58334L9.11901 1.932C8.68698 1.65005 8.18223 1.49992 7.66634 1.49992C7.15045 1.49992 6.64571 1.65005 6.21367 1.932L3.72367 3.58334C3.71621 3.58826 3.70887 3.59337 3.70167 3.59867L3.67367 3.602C2.89234 3.70734 2.25967 3.92867 1.76034 4.42734C1.26167 4.92667 1.04034 5.55934 0.935008 6.34067C0.833008 7.10067 0.833008 8.07067 0.833008 9.296V9.37067C0.833008 10.596 0.833008 11.5667 0.935008 12.326C1.04034 13.1073 1.26167 13.74 1.76034 14.2393C2.25967 14.738 2.89234 14.9593 3.67367 15.0647C4.43367 15.1667 5.40367 15.1667 6.62901 15.1667H8.70367C9.92901 15.1667 10.8997 15.1667 11.659 15.0647C12.4403 14.9593 13.073 14.738 13.5723 14.2393C14.1883 13.6227 14.385 12.7973 14.4563 11.726C14.3217 11.786 14.1763 11.822 14.0257 11.8313C13.9863 11.8333 13.9437 11.8333 13.899 11.8333H13.445C13.369 12.728 13.1977 13.2 12.865 13.532C12.583 13.814 12.1963 13.9833 11.5257 14.0733C10.841 14.1653 9.93767 14.1667 8.66634 14.1667H6.66634C5.39501 14.1667 4.49234 14.1653 3.80634 14.0733C3.13634 13.9833 2.74967 13.814 2.46767 13.532C2.18567 13.25 2.01634 12.8633 1.92634 12.1927C1.83434 11.508 1.83301 10.6047 1.83301 9.33334C1.83301 8.062 1.83434 7.15934 1.92634 6.47334C2.01634 5.80334 2.18567 5.41667 2.46767 5.13467C2.74967 4.85267 3.13634 4.68334 3.80701 4.59334C4.49234 4.50134 5.39501 4.5 6.66634 4.5H8.66634C9.93767 4.5 10.841 4.50134 11.5263 4.59334C12.1963 4.68334 12.583 4.85267 12.865 5.13467C13.1977 5.46667 13.369 5.93934 13.445 6.83334H13.8883H13.8997ZM6.62901 3.5H8.70367C9.04834 3.5 9.37234 3.5 9.67701 3.502L8.56634 2.76534C8.03301 2.412 7.29967 2.412 6.76634 2.76534L5.65501 3.502C5.96034 3.5 6.28434 3.5 6.62834 3.5"
                                      fill="#4B8700"
                                    />
                                    <path
                                      d="M4 6.16667C3.86739 6.16667 3.74021 6.21935 3.64645 6.31312C3.55268 6.40689 3.5 6.53406 3.5 6.66667C3.5 6.79928 3.55268 6.92646 3.64645 7.02022C3.74021 7.11399 3.86739 7.16667 4 7.16667H6.66667C6.79927 7.16667 6.92645 7.11399 7.02022 7.02022C7.11399 6.92646 7.16667 6.79928 7.16667 6.66667C7.16667 6.53406 7.11399 6.40689 7.02022 6.31312C6.92645 6.21935 6.79927 6.16667 6.66667 6.16667H4Z"
                                      fill="#4B8700"
                                    />
                                    <path
                                      d="M12.6663 9.33334C12.6663 9.51015 12.5961 9.67972 12.4711 9.80474C12.3461 9.92977 12.1765 10 11.9997 10C11.8229 10 11.6533 9.92977 11.5283 9.80474C11.4032 9.67972 11.333 9.51015 11.333 9.33334C11.333 9.15653 11.4032 8.98696 11.5283 8.86193C11.6533 8.73691 11.8229 8.66667 11.9997 8.66667C12.1765 8.66667 12.3461 8.73691 12.4711 8.86193C12.5961 8.98696 12.6663 9.15653 12.6663 9.33334Z"
                                      fill="#4B8700"
                                    />
                                  </svg>

                                  <h1>
                                    {jobs?.pay.rateType === "Range" && (
                                      <>
                                        &#163;{jobs?.pay.minimum} - &#163;
                                        {jobs?.pay.maximum}
                                      </>
                                    )}
                                    {jobs?.pay.rateType === "Fixed" && (
                                      <> &#163;{jobs?.pay.maximum} (Fixed)</>
                                    )}
                                    {jobs?.pay.rateType === "Hourly Rate" && (
                                      <>
                                        {" "}
                                        &#163;{" "}
                                        {!jobs?.pay.maximum
                                          ? jobs?.pay.minimum
                                          : jobs?.pay.maximum}
                                        per hour
                                      </>
                                    )}
                                    {jobs?.pay.rateType === "Hourly" && (
                                      <>
                                        {" "}
                                        &#163;
                                        {!jobs?.pay.maximum
                                          ? jobs?.pay.minimum
                                          : jobs?.pay.maximum}{" "}
                                        per hour
                                      </>
                                    )}
                                    {jobs?.pay.rateType === "Annually" && (
                                      <> &#163;{jobs?.pay.maximum} per year</>
                                    )}
                                    {jobs?.pay.rateType === "Monthly" && (
                                      <> &#163;{jobs?.pay.maximum} per month</>
                                    )}
                                    {jobs?.pay.rateType === "Yearly" && (
                                      <> &#163;{jobs?.pay.maximum} per year</>
                                    )}
                                    {jobs?.pay.rateType === "Weekly" && (
                                      <> &#163;{jobs?.pay.maximum} per week</>
                                    )}
                                    {jobs?.pay.rateType === "Per Day" && (
                                      <> &#163;{jobs?.pay.maximum} per day</>
                                    )}
                                    {jobs?.pay.rateType === "Per Project" && (
                                      <>
                                        {" "}
                                        &#163;{jobs?.pay.maximum} per project
                                      </>
                                    )}
                                  </h1>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                            {/* <div className="items-center mt-2 rating-section-outer">
                              <div className="rating-section">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.73366 15L4.46699 12.8667L2.06699 12.3333L2.30033 9.86667L0.666992 8L2.30033 6.13333L2.06699 3.66667L4.46699 3.13333L5.73366 1L8.00033 1.96667L10.267 1L11.5337 3.13333L13.9337 3.66667L13.7003 6.13333L15.3337 8L13.7003 9.86667L13.9337 12.3333L11.5337 12.8667L10.267 15L8.00033 14.0333L5.73366 15ZM7.30032 10.3667L11.067 6.6L10.1337 5.63333L7.30032 8.46667L5.86699 7.06667L4.93366 8L7.30032 10.3667Z"
                                    fill="#4B8700"
                                  />
                                </svg>

                                <span className="text-gray-500 ">Ratings</span>
                              </div>
                              <span className="text-yellow-400 Ratings-star">
                                ★★★★☆
                              </span>
                            </div> */}
                          </div>
                        </div>
                        <div className="flex items-center hrp-job-details md:justify-center">
                          <div className="jobreviewspan">
                            <span
                              className="block  text-sm font-semibold "
                              style={{ color: "#003366" }}
                            >
                              Job Type
                            </span>
                            <div className="quickview-flex flex items-center mb-2">
                              <div>
                                {jobs?.jobType?.length > 0
                                  ? jobs.jobType.map((type, index) => (
                                      <div className="flex items-center gap-1">
                                        {/* <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.33301 12H8.66634V10.6667H7.33301V12ZM7.99967 1.33334C7.1242 1.33334 6.25729 1.50578 5.44845 1.84081C4.63961 2.17584 3.90469 2.66691 3.28563 3.28596C2.03539 4.53621 1.33301 6.2319 1.33301 8.00001C1.33301 9.76812 2.03539 11.4638 3.28563 12.7141C3.90469 13.3331 4.63961 13.8242 5.44845 14.1592C6.25729 14.4942 7.1242 14.6667 7.99967 14.6667C9.76778 14.6667 11.4635 13.9643 12.7137 12.7141C13.964 11.4638 14.6663 9.76812 14.6663 8.00001C14.6663 7.12453 14.4939 6.25762 14.1589 5.44879C13.8238 4.63995 13.3328 3.90502 12.7137 3.28596C12.0947 2.66691 11.3597 2.17584 10.5509 1.84081C9.74206 1.50578 8.87515 1.33334 7.99967 1.33334ZM7.99967 13.3333C5.05967 13.3333 2.66634 10.94 2.66634 8.00001C2.66634 5.06001 5.05967 2.66668 7.99967 2.66668C10.9397 2.66668 13.333 5.06001 13.333 8.00001C13.333 10.94 10.9397 13.3333 7.99967 13.3333ZM7.99967 4.00001C7.29243 4.00001 6.61415 4.28096 6.11406 4.78106C5.61396 5.28116 5.33301 5.95943 5.33301 6.66668H6.66634C6.66634 6.31305 6.80682 5.97392 7.05687 5.72387C7.30691 5.47382 7.64605 5.33334 7.99967 5.33334C8.3533 5.33334 8.69243 5.47382 8.94248 5.72387C9.19253 5.97392 9.33301 6.31305 9.33301 6.66668C9.33301 8.00001 7.33301 7.83334 7.33301 10H8.66634C8.66634 8.50001 10.6663 8.33334 10.6663 6.66668C10.6663 5.95943 10.3854 5.28116 9.88529 4.78106C9.38519 4.28096 8.70692 4.00001 7.99967 4.00001Z"
                                            fill="#4B8700"
                                          />
                                        </svg> */}

                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                          width="16"
                                          height="16"
                                        >
                                          <path
                                            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                                            fill="#4B8700"
                                          />
                                        </svg>
                                        <span
                                          key={index}
                                          className="font-medium"
                                        >
                                          {type} &nbsp;
                                        </span>
                                      </div>
                                    ))
                                  : ""}
                              </div>
                            </div>
                            <span
                              className="block  text-sm font-semibold "
                              style={{ color: "#003366" }}
                            >
                              Experience
                            </span>
                            <div className="flex items-center gap-1 mb-2">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.833 1.83333H3.16634C2.42996 1.83333 1.83301 2.43028 1.83301 3.16666V12.8333C1.83301 13.5697 2.42996 14.1667 3.16634 14.1667H12.833C13.5694 14.1667 14.1663 13.5697 14.1663 12.8333V3.16666C14.1663 2.43028 13.5694 1.83333 12.833 1.83333Z"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10.917 6.50001C10.5413 6.50001 10.1809 6.35075 9.91526 6.08507C9.64958 5.8194 9.50033 5.45906 9.50033 5.08334C9.50033 4.70762 9.35107 4.34728 9.08539 4.0816C8.81972 3.81593 8.45938 3.66667 8.08366 3.66667H7.91699C7.54127 3.66667 7.18093 3.81593 6.91526 4.0816C6.64958 4.34728 6.50033 4.70762 6.50033 5.08334C6.50033 5.45906 6.35107 5.8194 6.08539 6.08507C5.81972 6.35075 5.45938 6.50001 5.08366 6.50001C4.70794 6.50001 4.3476 6.64926 4.08192 6.91494C3.81625 7.18061 3.66699 7.54095 3.66699 7.91667V8.08334C3.66699 8.45906 3.81625 8.8194 4.08192 9.08507C4.3476 9.35075 4.70794 9.50001 5.08366 9.50001C5.45938 9.50001 5.81972 9.64926 6.08539 9.91494C6.35107 10.1806 6.50033 10.5409 6.50033 10.9167C6.50033 11.2924 6.64958 11.6527 6.91526 11.9184C7.18093 12.1841 7.54127 12.3333 7.91699 12.3333H8.08366C8.45938 12.3333 8.81972 12.1841 9.08539 11.9184C9.35107 11.6527 9.50033 11.2924 9.50033 10.9167C9.50033 10.5409 9.64958 10.1806 9.91526 9.91494C10.1809 9.64926 10.5413 9.50001 10.917 9.50001C11.2927 9.50001 11.6531 9.35075 11.9187 9.08507C12.1844 8.8194 12.3337 8.45906 12.3337 8.08334V7.91667C12.3337 7.54095 12.1844 7.18061 11.9187 6.91494C11.6531 6.64926 11.2927 6.50001 10.917 6.50001Z"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.1231 9.00167C10.8575 8.736 10.7082 8.3757 10.7082 8.00001C10.7082 7.62432 10.8575 7.26401 11.1231 6.99834C11.3887 6.73267 11.538 6.37236 11.538 5.99667C11.538 5.62098 11.3887 5.26068 11.1231 4.99501L11.0051 4.87701C10.7394 4.61137 10.3791 4.46214 10.0034 4.46214C9.62775 4.46214 9.26745 4.61137 9.00178 4.87701C8.87022 5.00859 8.71404 5.11296 8.54214 5.18417C8.37025 5.25539 8.18601 5.29204 7.99994 5.29204C7.81388 5.29204 7.62964 5.25539 7.45775 5.18417C7.28585 5.11296 7.12966 5.00859 6.99811 4.87701C6.73244 4.61137 6.37213 4.46214 5.99644 4.46214C5.62075 4.46214 5.26045 4.61137 4.99478 4.87701L4.87678 4.99467C4.61114 5.26034 4.46191 5.62065 4.46191 5.99634C4.46191 6.37203 4.61114 6.73234 4.87678 6.99801C5.00836 7.12956 5.11273 7.28574 5.18394 7.45764C5.25516 7.62954 5.29181 7.81378 5.29181 7.99984C5.29181 8.1859 5.25516 8.37014 5.18394 8.54204C5.11273 8.71394 5.00836 8.87012 4.87678 9.00167C4.61114 9.26734 4.46191 9.62765 4.46191 10.0033C4.46191 10.379 4.61114 10.7393 4.87678 11.005L4.99444 11.123C5.26012 11.3886 5.62042 11.5379 5.99611 11.5379C6.3718 11.5379 6.73211 11.3886 6.99778 11.123C7.12933 10.9914 7.28552 10.8871 7.45741 10.8158C7.62931 10.7446 7.81355 10.708 7.99961 10.708C8.18567 10.708 8.36991 10.7446 8.54181 10.8158C8.71371 10.8871 8.86989 10.9914 9.00144 11.123C9.26712 11.3886 9.62742 11.5379 10.0031 11.5379C10.3788 11.5379 10.7391 11.3886 11.0048 11.123L11.1228 11.0053C11.3884 10.7397 11.5376 10.3794 11.5376 10.0037C11.5376 9.62798 11.3884 9.26768 11.1228 9.00201"
                                  stroke="#3E3E3E"
                                  strokeWidth="0.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                              <div>
                                <span className="font-normal">
                                  {jobs?.experience ||
                                    "Experience not specified"}
                                </span>
                              </div>
                            </div>
                            <span
                              className="block  text-sm font-semibold "
                              style={{ color: "#003366" }}
                            >
                              Proposal
                            </span>
                            <div className="flex items-center gap-1">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.66634 14C2.29967 14 1.9859 13.8696 1.72501 13.6087C1.46412 13.3478 1.33345 13.0338 1.33301 12.6667V10H5.99967V11.3333H9.99967V10H14.6663V12.6667C14.6663 13.0333 14.5359 13.3473 14.275 13.6087C14.0141 13.87 13.7001 14.0004 13.333 14H2.66634ZM7.33301 10V8.66666H8.66634V10H7.33301ZM1.33301 8.66666V5.33333C1.33301 4.96666 1.46367 4.65288 1.72501 4.39199C1.98634 4.13111 2.30012 4.00044 2.66634 3.99999H5.33301V2.66666C5.33301 2.29999 5.46368 1.98622 5.72501 1.72533C5.98634 1.46444 6.30012 1.33377 6.66634 1.33333H9.33301C9.69967 1.33333 10.0137 1.46399 10.275 1.72533C10.5363 1.98666 10.6668 2.30044 10.6663 2.66666V3.99999H13.333C13.6997 3.99999 14.0137 4.13066 14.275 4.39199C14.5363 4.65333 14.6668 4.96711 14.6663 5.33333V8.66666H9.99967V7.33333H5.99967V8.66666H1.33301ZM6.66634 3.99999H9.33301V2.66666H6.66634V3.99999Z"
                                  fill="#3E3E3E"
                                />
                              </svg>

                              <div>
                                <span className="font-normal">
                                  {" "}
                                  {jobs?.proposal <= 5
                                    ? "Less Than 5"
                                    : "Greater Than 5"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(User == null || User.role === "applicant") && (
                          <div className="hrp-job-width md:mt-0 mt-4">
                            <div className="">
                              <button
                                className="hrp-btn-white flex justify-center"
                                onClick={(e) =>
                                  handleToggleSaveJob(e, segments[2])
                                }
                              >
                                &nbsp;
                                <FeatherIcon
                                  icon="heart"
                                  fill={
                                    JobData?.saved === "Not saved"
                                      ? "#fff"
                                      : "#ffa500"
                                  }
                                  stroke="#ffa500"
                                />
                                {JobData?.saved === "Not saved"
                                  ? "Save Job"
                                  : "Already Saved"}
                              </button>
                              {JobData?.applied?.status === "Applied" ? (
                                <button className="hrp-btn mt-2 " disabled>
                                  Already Applied
                                </button>
                              ) : (
                                <button
                                  className="hrp-btn mt-2"
                                  onClick={(e) => jobApply(segments[2])}
                                >
                                  Apply For this Position
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="hrp-profile-shere cursor-pointer">
                          <svg
                            onClick={(e) => handleShare(e)}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.116 8.99167 12.237 8.975 12.363C8.95833 12.489 8.93333 12.6013 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z"
                              fill="#003366"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section-preview hrp-tittle-section mx-auto py-6 bg-white rounded-md">
                    <div className="hrp-title">
                      {jobs?.title || "Job Title Not Available"}
                    </div>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>Company:</strong>{" "}
                      {jobs?.company?.name || "N/A"}
                    </p>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>
                        About Company:
                      </strong>{" "}
                      {decodeHtmlEntities(jobs?.company?.description) ||
                        "No description available"}
                    </p>
                    {jobs?.workhours && (
                      <p className="mb-4">
                        <strong style={{ color: "#003366" }}>
                          Hours Per Week:
                        </strong>{" "}
                        {jobs?.workhours} hours/week
                      </p>
                    )}
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>
                        Job Description:
                      </strong>{" "}
                      <p
                        className="job-description"
                        dangerouslySetInnerHTML={{
                          __html: jobs?.description
                            ? jobs.description.replace(/\n/g, "<br/>") // Replaces line breaks with <br/> for HTML formatting
                            : "",
                        }}
                      />
                    </p>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>
                        Key And Responsibility:
                      </strong>
                    </p>
                    {jobs?.Key_responsibility?.length > 0 ? (
                      <ul style={{ listStyle: "inside" }}>
                        {Array.isArray(jobs.Key_responsibility)
                          ? jobs.Key_responsibility.flatMap((responsibility) =>
                              responsibility.split(/\r?\n/)
                            )
                              .filter(
                                (responsibility) => responsibility.trim() !== ""
                              )
                              .map((responsibility, index) => (
                                <li key={index}>
                                  {responsibility.trim().replace(/^•\s*/, "")}
                                </li>
                              ))
                          : jobs.Key_responsibility.split(/\r?\n/)
                              .filter(
                                (responsibility) => responsibility.trim() !== ""
                              )
                              .map((responsibility, index) => (
                                <li key={index}>
                                  {responsibility.trim().replace(/^•\s*/, "")}
                                </li>
                              ))}
                      </ul>
                    ) : (
                      <p>No responsibilities listed.</p>
                    )}
                    <p className="mb-4 mt-3">
                      <strong style={{ color: "#003366" }}>Eligibility:</strong>
                    </p>
                    <div className="hrp-table-Preview hrp-table-mobile-responcive">
                      <table style={styles.table}>
                        <thead>
                          <tr style={styles.rowstyle}>
                            <th className="font-semibold" style={styles.header}>
                              Category
                            </th>
                            <th style={styles.header}>Details</th>
                            <th style={styles.header}>
                              Level of Qualification
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs?.educationLevel?.length > 0 ? (
                            jobs.educationLevel.map((qualification, qIndex) => (
                              <React.Fragment key={qIndex}>
                                {qualification?.details?.map(
                                  (detail, dIndex) => (
                                    <tr key={dIndex} style={styles.rowstyle}>
                                      {dIndex === 0 && (
                                        <td
                                          rowSpan={qualification.details.length}
                                          style={styles.categoryCell}
                                        >
                                          <div style={styles.text}>
                                            <p style={styles.categoryText}>
                                              {qualification.qualification ||
                                                "N/A"}
                                            </p>
                                          </div>
                                        </td>
                                      )}
                                      <td style={styles.cell}>
                                        <div style={styles.text2}>
                                          {detail.details || "N/A"}
                                        </div>
                                      </td>
                                      <td style={styles.crossWithTd}>
                                        <div style={styles.text2}>
                                          {detail.level_of_qualification ||
                                            "N/A"}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3" style={styles.cell}>
                                No education details available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>Skills:</strong>
                    </p>
                    <ul className="list-disc list-inside mb-4">
                      {jobs?.skills?.length > 0 ? (
                        jobs.skills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))
                      ) : (
                        <li>No skills listed.</li>
                      )}
                    </ul>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>Benefits:</strong>
                    </p>
                    <ul className="list-disc list-inside mb-4">
                      {jobs?.benefits?.length > 0 ? (
                        jobs.benefits.map((benefit, index) => (
                          <li key={index}>{benefit}</li>
                        ))
                      ) : (
                        <li>No benefits listed.</li>
                      )}
                    </ul>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>Schedule:</strong>{" "}
                      {jobs?.shift?.join(", ") || ""}
                    </p>
                    <p className="mb-4">
                      <strong style={{ color: "#003366" }}>
                        Supplemental pay types:
                      </strong>{" "}
                      {jobs?.supplementalPay?.join(", ") || "None"}
                    </p>
                    {jobs?.additional_information ? (
                      <p className="mb-4">
                        <strong style={{ color: "#003366" }}>
                          Additional Information:
                        </strong>{" "}
                        {jobs.additional_information}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {(User == null || User.role === "applicant") && (
                    <div className="hrp-sub-section-4">
                      <div className="hrp-sub-btn-section my-10">
                        {/* "Report Job" button */}
                        <button
                          className="hrp-btn-white flex"
                          onClick={(e) => handleClickReport(e)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 20.5V5H13.192L13.592 7H19V15H13.808L13.408 13H7V20.5H6Z"
                              fill="#003366"
                            />
                          </svg>
                          &nbsp;
                          {JobData?.reported === "Unreported"
                            ? "Report job"
                            : "Already Reported"}
                        </button>
                        {JobData?.applied?.status === "Applied" ? (
                          <button className="hrp-btn mt-2" disabled>
                            Already Applied
                          </button>
                        ) : (
                          <button
                            className="hrp-btn mt-2"
                            onClick={(e) => jobApply(segments[2])}
                          >
                            Apply For this Position
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="hrp-sub-section min-h-screen flex justify-center bg-gray-100">
              <div className="container m-auto mt-0 p-6">
                <div className="bg-white rounded-lg shadow-lg p-8 w-1/2 mt-20 m-auto">
                  <h2
                    className="text-2xl font-semibold italic text-center mb-4"
                    style={{ color: "#FF0000", whiteSpace: "pre-line" }}
                  >
                    {error}
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>

        {loading && (
          <div className="spinnerload" id="RoadmapSpinnerDiv">
            <span className="RoadmapSpinner"></span>
          </div>
        )}
      </div>
      <VisitorTracker />
    </>
  );
};
export default SingleJob;
