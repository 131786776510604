import { useEffect, useState } from "react";
import api from "../../Api"; // Adjust the path based on your API setup

const VisitorTracker = () => {
  const [clicks, setClicks] = useState(0);
  const [location, setLocation] = useState({ lat: null, long: null });
  const [hasFetchedLocation, setHasFetchedLocation] = useState(false);
  const [dataSent, setDataSent] = useState(false);
  const [visitorHash, setVisitorHash] = useState("");
  const startTime = Date.now();

  const LOCAL_STORAGE_KEY = "visitorData";

  // Function to generate a unique UUID-like hash
  const generateUUID = () => {
    const randomHex = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    const uniqueComponent = btoa(navigator.userAgent + Date.now());
    return (
      `${randomHex()}-${randomHex()}-4${randomHex().substr(1, 3)}-` +
      `${(8 + Math.random() * 4).toString(16).substr(0, 1)}${randomHex().substr(
        1,
        3
      )}-` +
      `${uniqueComponent.substr(0, 8)}`
    );
  };

  // Function to get the current date and time
  const getCurrentDateTime = () => {
    const now = new Date();
    return {
      date: now.toISOString().split("T")[0], // YYYY-MM-DD
      time: now.toTimeString().split(" ")[0], // HH:MM:SS
      timestamp: now.getTime(), // Epoch time
    };
  };

  // Function to get or create visitor hash and save time/date
  const getOrCreateVisitorHash = () => {
    let visitorData = localStorage.getItem(LOCAL_STORAGE_KEY);
    const currentTime = getCurrentDateTime();

    if (visitorData) {
      // Update timestamp if already exists
      visitorData = JSON.parse(visitorData);
      visitorData.timestamp = currentTime.timestamp;
      visitorData.date = currentTime.date;
      visitorData.time = currentTime.time;

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(visitorData));
      setVisitorHash(visitorData.hash);
    } else {
      // Create a new visitor hash
      const newVisitorData = {
        hash: generateUUID(),
        date: currentTime.date,
        time: currentTime.time,
        timestamp: currentTime.timestamp,
      };

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newVisitorData));
      setVisitorHash(newVisitorData.hash);
    }
  };

  useEffect(() => {
    // Track clicks on the page
    const handleDocumentClick = () => {
      setClicks((prevClicks) => prevClicks + 1);
      getOrCreateVisitorHash(); // Update visitor timestamp on user activity
    };
    document.addEventListener("click", handleDocumentClick);

    // Check for geolocation permission only once when the component mounts
    const checkGeolocationPermission = async () => {
      if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        if (permissionStatus.state === "granted" && !hasFetchedLocation) {
          getGeolocation();
        }
      }
    };

    // Function to request geolocation
    const getGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
            setHasFetchedLocation(true);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    // Log visitor data
    const logVisitorData = async () => {
      if (dataSent) return; // Don't log if data has already been sent

      const endTime = Date.now();
      const timeSpent = (endTime - startTime) / 1000; // time in seconds

      if (location.lat !== null && location.long !== null) {
        const visitorData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

        const apiData = {
          visitorHash: visitorData.hash, // Include visitor hash
          referer: document.referrer,
          date: visitorData.date, // Date from localStorage
          timeSpent,
          url: window.location.href,
          site: window.location.host,
          pageUrl: window.location.pathname,
          clicks,
          lat: location.lat,
          long: location.long,
        };

        try {
          const res = await api.PostVisitor(apiData);
          if (res.status === 201) {
            setDataSent(true); // Mark data as sent
          }
        } catch (error) {
          console.error("Error sending visitor data:", error);
        }
      } else {
        console.warn("Location data not available yet.");
      }
    };

    // Check geolocation permission
    checkGeolocationPermission();

    // Generate or fetch the visitor hash
    getOrCreateVisitorHash();

    // Log visitor data once when the component mounts
    logVisitorData();

    // Cleanup
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [clicks, location, hasFetchedLocation, dataSent, visitorHash]);

  return null; // This component doesn't need to render anything
};

export default VisitorTracker;
