import React, { useState, useRef, useEffect } from "react";
import { getUserData } from "../../../helpers/utils";
import SearchableDropdown from "../../../helpers/SearchableDropdown";
import { jobSectors } from "../../../helpers/Object";
import api from "../../../Api";
import CustomToastContainer from "../../Common/CustomToastContainer";
import axios from "axios";
import logoimage from "../../../image/company-logo-transparent-png-19.png";
import { useHistory } from "react-router-dom";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const CompanySettings = ({ onClose, onSave, initialValues }) => {
  const [companyName, setCompanyName] = useState("");
  const user = getUserData();
  const inputRef = useRef();
  const [addressData, setAddressData] = useState({});
  const [companyimg, setcompanyimg] = useState(null);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  useEffect(() => {
    if (initialValues) {
      setCompanyName(initialValues.name || "");
      setCompanyAddress(initialValues.address || "");
      setCompanyIndustry(initialValues.industry || "");
      setCompanyDescription(initialValues.description || "");
      setCompanyLogo(initialValues.image || "");
    }
  }, [initialValues]);
  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" }, // Modify this as needed for country restriction
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;
        const addressData = {
          address: place.formatted_address,
          city: "",
          area: "",
          pincode: "",
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        };

        // Parsing the components to get city, area, postal code
        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("locality")) {
            addressData.city = component.long_name;
          }
          if (types.includes("sublocality") || types.includes("neighborhood")) {
            addressData.area = component.long_name;
          }
          if (types.includes("postal_code")) {
            addressData.pincode = component.long_name;
          }
        });

        // Set address details and formatted address
        setAddressData(addressData);
        setCompanyAddress(place.formatted_address);
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, []);
  const handleUpload = async (selectedFile) => {
    setLoading(true);

    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/gif",
      "image/jiff",
    ];

    // Check if file type is one of the valid image types
    if (!validTypes.includes(selectedFile.type)) {
      setLoading(false);
      toastRef.current.addToast(
        "Only images are allowed (JPG, PNG, WEBP, GIF, JIFF)!",
        "error"
      );
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      const response = await axios.post(
        `${domainpath}/api/upload/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.imagePath) {
        setcompanyimg(response.data.imagePath);
        setCompanyLogo(response.data.imagePath);
        setLoading(false);

        toastRef.current.addToast("Image uploaded successfully", "success");
      } else {
        setLoading(false);

        toastRef.current.addToast(response?.message, "error");
      }
    } catch (error) {
      toastRef.current.addToast("Error uploading image", "error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: companyName,
      address: companyAddress,
      industry: companyIndustry,
      description: companyDescription,
      image: companyLogo,
    };

    try {
      let response;
      if (initialValues) {
        // Update API if initialValues exist
        response = await axios.put(
          `${domainpath}/api/company/${initialValues._id}`,
          formData
        );
      } else {
        // Add new company API
        response = await axios.post(`${domainpath}/api/company`, formData);
      }

      if (response.status === 201 || response.status === 200) {
        toastRef.current.addToast(response.data.message, "success");
        setTimeout(() => {
          onSave(response.data.company); // Pass updated/new company data
          onClose();
        }, 500);
      } else {
        toastRef.current.addToast(response.data.message, "error");
      }
    } catch (error) {
      console.error("Error submitting company data:", error);
      toastRef.current.addToast("Error saving company details", "error");
    }
  };
  return (
    <div className=" flex justify-center h-screen  login_inform-inner m-auto p-20 job-page">
      <div className="flex ">
        <div className="form_upto  jobapply-width m-3 loginDiv" id="step_1">
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4">
            <>
              <h1 className="hrp-heading-size text-center hrp-About">
                {!initialValues && initialValues != undefined
                  ? "Edit Company "
                  : "Add Company"}
              </h1>
              <form onSubmit={handleSubmit} className="formCompanysetting">
                {/* Company Logo Upload */}
                <div className="relative lg:w-full lg:h-40 hrp-profile-header-image ">
                  <label
                    htmlFor="companyimg"
                    className="absolute transform -translate-x-1/2 -translate-y-1/2 text-gray-700 font-medium hrp-label-position"
                  >
                    <input
                      type="file"
                      id="companyimg"
                      accept="image/*"
                      name="companyimg"
                      onChange={(e) => handleUpload(e.target.files[0])}
                      className="hidden"
                    />

                    <div className="w-full bg-white rounded-full flex items-center justify-center border-2 border-white hrp-profile-section-image relative group">
                      <img
                        className="w-full h-full rounded-full"
                        src={
                          companyLogo
                            ? companyLogo.startsWith("http")
                              ? companyLogo
                              : `${domainpath}/uploads/${companyLogo}`
                            : logoimage
                        }
                        alt={`${companyName} Logo`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = logoimage; // Replace with a default logo
                        }}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full">
                        <span className="text-white text-sm font-semibold text-center">
                          Change Logo
                        </span>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="grid lg:grid-cols-2 lg:gap-5">
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        id="companyName"
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        type="text"
                        placeholder=""
                        required
                      />
                      <label htmlFor="companyName" className="profileheadline">
                        Company Name
                      </label>
                    </div>
                  </div>
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <input
                        id="companyAddress"
                        className="border rounded w-full py-2 px-3 text-gray-700"
                        defaultValue={companyAddress}
                        ref={inputRef} // Input ref for Google Autocomplete
                        type="text"
                        placeholder="Enter company address"
                        required
                      />
                      <label
                        htmlFor="companyAddress"
                        className="profileheadline"
                      >
                        Company Address
                      </label>
                    </div>
                  </div>
                </div>
                <div className="grid lg:grid-cols-2 lg:gap-5">
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <>
                        <SearchableDropdown
                          options={jobSectors.map((sector) => ({
                            value: sector.value,
                            label: String(sector.label),
                          }))}
                          className="mt-1 block w-full py-2 px-3 border input-border-color bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"
                          multiple={false}
                          name="industry"
                          value={jobSectors.find(
                            (sector) => sector.value === companyIndustry
                          )}
                          onChange={(selectedOption) => {
                            // Check if `selectedOption` is valid and not undefined
                            if (selectedOption) {
                              setCompanyIndustry(selectedOption);
                            }
                          }}
                        />
                        <label className="block text-sm font-medium text-gray-700 SearchableDropdown">
                          Job Industry / Sector
                        </label>
                      </>
                    </div>
                  </div>
                  <div className="animated-form profileheadlinediv">
                    <div className="form-group">
                      <textarea
                        id="companyDescription"
                        className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer custom-scrollbar"
                        value={companyDescription}
                        onChange={(e) => setCompanyDescription(e.target.value)}
                        placeholder=""
                        required
                      />
                      <label
                        htmlFor="companyDescription"
                        className="form-input-label absolute top-2 left-4  peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                      >
                        Company Description
                      </label>
                    </div>
                  </div>
                </div>
                {error && <div className="text-red-500">{error}</div>}

                {/* Actions */}
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500 joblist-cardbtnapply"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-10 py-2 bg-blue-600 text-white rounded hover:bg-blue-700  joblist-btnclear"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          </div>
        </div>
        <button
          className="   text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 close-btn-style"
          onClick={onClose}
        >
          &times;
        </button>
      </div>{" "}
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default CompanySettings;
