import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import api from "../../Api";
import axios from "axios";
import * as XLSX from "xlsx";
import CustomToastContainer from "../Common/CustomToastContainer";
import Select2 from "../Common/Select2Wrapper";
import FeatherIcon from "feather-icons-react";
import CustomDataTable from "../Common/Customsdatatable";
const domainpath = process.env.REACT_APP_DOMAIN;
const domainPath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const ManageJobs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState(""); // Default status
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [page, setPage] = useState(1);
  const [isSearchMode, setIsSearchMode] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const toastRef = useRef();
  const statusref = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const handleDelete = async (jobId) => {
    const response = await api.deletejob(`${jobId}`);
    if (response.status === "200") {
      toastRef.current.addToast(response.message, "success");
      setTimeout(() => {
        fetchJobs(page); // Reload data after deletion
      }, 3000);
    } else {
      toastRef.current.addToast(
        "There was an error deleting the job!",
        "error"
      );
    }
  };
  //  const PrintDownload = () => {
  // const handlePrint = () => {
  //   window.print(); // Opens the browser's print dialog
  // };

  const flattenJobData = (job) => {
    console.log("Job Object:", job);
    return {
      "Job ID": job._id,
      Title: job.title,
      Industry: job.industry,
      Description: job.description,
      Location: `${job.location.address}, ${job.location.city}, ${job.location.pincode}`,
      "Job Type": job.jobType.join(", "),
      "Salary (Min)": job.pay.minimum,
      "Salary (Max)": job.pay.maximum,
      "Rate Type": job.pay.rateType,
      Experience: job.experience,
      "Work Hours": `${job.workhours} hrs/week`,
      Shift: job.shift.join(", "),
      "Number of Positions": job.numberOfPositions,
      Skills: job.skills.join(", "),
      Categories: job.categories.join(", "),
      "Company Name": job.company.name,
      "Company Description": job.company.description,
      "Key Responsibilities": Array.isArray(job.Key_responsibility)
        ? job.Key_responsibility.join("; ")
        : job.Key_responsibility || "N/A",
      Benefits: job.benefits.join(", "),
      "Application Methods": job.applicationMethod.join(", "),
      "Application Deadline": job.applicationDeadline.split("T")[0],
      "Posted Date": job.postedDate.split("T")[0],
      Status: job.status,
      "Total Applicants": job.totalApplicants,
      "Job Views": job.jobViews,
    };
  };

  const handleDownload = () => {
    // 1. Flatten all jobs into rows
    console.log(filteredData);

    // const jobRows = filteredData.map(async (job) => {
    //   const flatJob = await flattenJobData(job);
    //   console.log("Flattened Job:", flatJob);
    //   return flatJob;
    // });
    const jobRows = filteredData.map(flattenJobData);
    // 2. Create a worksheet
    console.log("Job Rows:", jobRows);
    const ws = XLSX.utils.json_to_sheet(jobRows);
    console.log("Worksheet:", ws);

    // 3. Optional: Add column widths
    ws["!cols"] = [
      { wch: 20 }, // Job ID
      { wch: 30 }, // Title
      { wch: 20 }, // Industry
      { wch: 50 }, // Description
      { wch: 40 }, // Location
      { wch: 15 }, // Job Type
      { wch: 12 }, // Salary Min
      { wch: 12 }, // Salary Max
      { wch: 15 }, // Experience
      { wch: 15 }, // Work Hours
      { wch: 15 }, // Shift
      { wch: 20 }, // Number of Positions
      { wch: 40 }, // Skills
      { wch: 30 }, // Categories
      { wch: 30 }, // Company Name
      { wch: 50 }, // Company Description
      { wch: 50 }, // Responsibilities
      { wch: 40 }, // Benefits
      { wch: 30 }, // Application Methods
      { wch: 15 }, // Application Deadline
      { wch: 15 }, // Posted Date
      { wch: 10 }, // Status
      { wch: 10 }, // Applicants
      { wch: 10 }, // Views
    ];

    // 4. Create a workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "All Jobs");

    // 5. Export as an Excel file
    XLSX.writeFile(wb, "all_jobs.xlsx");
  };
  const statusOptions = [
    { value: "Publish", label: "Published", color: "#0085FF" },
    { value: "Hold", label: "Hold", color: "#003366" },
    { value: "Disabled", label: "Disabled", color: "#FFE513" },
    { value: "Closed", label: "Closed", color: "#FF0000" },
    { value: "Draft", label: "Draft", color: "#FFA500" },
    { value: "Block", label: "Block", color: "#000000" },
  ];

  const fetchJobs = async (currentPage = 1) => {
    if (isSearchMode) return;
    setLoading(true);
    try {
      const response = showMessages
        ? await api.getAlljobsForAdmin(currentPage, perPage)
        : await api.getJobsByReported(currentPage, perPage);

      if (response.status === 200) {
        const jobs = (response.jobsWithApplicants || response.reportedJobs).map(
          (job) => ({
            ...job,
            canEdit: true, // Set default to true; adjust based on your requirements
            canDelete: true, // Set default to true; adjust based on your requirements
            canView: true,
            canShare: true, // Set default to true; adjust based on your requirements
          })
        );
        setData(jobs);
        setFilteredData(jobs);
        setTotalRows(response.totalCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs(page);
  }, [page, perPage, searchTerm, showMessages]);

  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  const handlePerRowsChange = async (newPerPage) => {
    if (newPerPage !== perPage) {
      setPerPage(newPerPage);
      setPage(1); // Reset to the first page
    }
  };

  const handleUpdateJobStatus = async (e, id) => {
    setLoading(true);
    e.preventDefault();
    const formdata = { status: e.target.value };
    try {
      const res = await api.UpdateJObStatus(id, formdata);
      toastRef.current.addToast(res.message, "success");
      fetchJobs(page); // Reload data after status update
    } catch (err) {
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  const handlegetJobBystatus = async (e) => {
    setLoading(true);
    e.preventDefault();
    const status = e.target.value;
    try {
      const response = await api.SortJObbyStatusByadmin(status);
      const jobs = (response.jobsData || response.jobsData).map((job) => ({
        ...job,
        canEdit: true, // Set default to true; adjust based on your requirements
        canDelete: true, // Set default to true; adjust based on your requirements
        canView: true, // Set default to true; adjust based on your requirements
        canShare: true, // Set default to true; adjust based on your requirements
      }));
      setData(jobs);
      setFilteredData(jobs); // Update job list
      setTotalRows(response.totalRecords);
      setPage(response.currentPage);
      setTotalPages(response.totalPages);
      setStatusFilter(status); // Save selected status
    } catch (err) {
      toastRef.current.addToast(err, "error");
    } finally {
      setLoading(false);
    }
  };
  const status = [
    { value: "Publish", label: "Published", color: "#0085FF" },
    { value: "Hold", label: "Hold", color: "#003366" },
    { value: "Disabled", label: "Disabled", color: "#FFE513" },
    { value: "Closed", label: "Closed", color: "#FF0000" },
    { value: "Draft", label: "Draft", color: "#FFA500" },
    { value: "Block", label: "Block", color: "#000000" },
  ];
  const filterData = (jobs, keyword) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    const filtered = jobs.filter((job) => {
      const titleMatch = job.title?.toLowerCase().includes(lowerCaseKeyword);
      const companyMatch = job.company?.name
        .toLowerCase()
        .includes(lowerCaseKeyword);
      const locationMatch =
        job.location?.city?.toLowerCase().includes(lowerCaseKeyword) ||
        job.location?.area?.toLowerCase().includes(lowerCaseKeyword) ||
        job.location?.address?.toLowerCase().includes(lowerCaseKeyword);
      return titleMatch || companyMatch || locationMatch;
    });
    setFilteredData(filtered);
    setTotalRows(filtered.length); // Update total rows for filtered data
  };

  // Handle search term change
  const handleSearchChange = async (e) => {
    e.preventDefault();
    setIsSearchMode(true);

    const search = e.target.value;
    setSearchTerm(search);
    try {
      const response = await axios.get(`${domainPath}/api/job/search-jobs`, {
        params: {
          title: search, // Include employerId dynamically
          page: 1,
          limit: 10,
        },
      });

      const { jobsData, totalRecords, totalPages } = response.data;

      // Safely update state
      setFilteredData(jobsData || []);
      setData(jobsData || []);
      setTotalRows(totalRecords || 0);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleEdit = (jobId) => {
    history.push(`./jobpost/${jobId}`);
  };
  const handleShare = async (e, jobs) => {
    e.preventDefault();

    // Extract details
    const jobId = jobs?._id; // Assuming you have this value from the `jobs` object
    const jobTitle = jobs?.title; // Replace with actual job title if dynamic
    const companyName = jobs?.company?.name || ""; // Replace with actual company name if dynamic

    // Construct the URL
    const shareUrl = `${domainpath}/jobs/${jobId}?${new URLSearchParams({
      job: jobTitle,
      company: companyName,
    }).toString()}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: `Check out this Job: ${jobs?.title}`,
          url: shareUrl,
        });
        // console.log("Profile shared successfully");
      } catch (error) {
        if (error.name === "AbortError") {
          console.warn("User aborted the share action.");
        } else {
          console.error("Error sharing profile:", error);
        }
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert("Profile link copied to clipboard!");
      } catch (clipboardError) {
        console.error("Failed to copy to clipboard:", clipboardError);
      }
    }
  };
  const handleView = (id) => {
    history.push(`/jobs/${id}`);
  };
  const columns = showMessages
    ? [
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row?._id}?job=${row?.title.replace(
                  /\s+/g,
                  "-"
                )}&company=${row?.company?.name.replace(/\s+/g, "-")}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "18px", fontWeight: 600 }}
              >
                {row?.title || ""}
              </a>

              <div style={{ color: "#003366", fontSize: "13px" }}>
                {" "}
                {row.company ? `${row.company?.name}` : ""}
              </div>
              <div style={{ fontSize: "13px" }}>
                {row?.location?.address ||
                  `${row?.location?.city} ${row?.location?.area} ${row?.location?.pincode}`}
              </div>
            </>
          ),
          width: "25%",
          sortable: true,
        },
        {
          name: "Views",
          selector: (row) => (
            // <div
            //   className="relative job-search-label-section singleselect2 statusenroll cursor-pointer"
            //   onClick={() => history.push(`/dashboard/job-views/${row._id}`)}
            // >
            //   {row.jobViews}
            // </div>
            <span
              // href={`/dashboard/job/${row._id}`}
              onClick={() => history.push(`/dashboard/job-views/${row._id}`)}
              className="cursor-pointer flex items-center"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row?.jobViews}
              <FeatherIcon icon="eye" className="w-5 ml-2" />
            </span>
          ),
          sortable: true,
          width: "10%",
        },

        {
          name: "Applicant",
          selector: (row) => (
            <a
              href={`/dashboard/job/${row._id}`}
              className="hover:underline"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.totalApplicants}
            </a>
          ),
          sortable: true,
          width: "10%",
        },
        {
          name: "Shortlisted",
          selector: (row) => (
            <a
              href={`/dashboard/job/${row._id}`}
              className="hover:underline"
              style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
            >
              {row.shortlistedApplicants}
            </a>
          ),
          sortable: true,
          width: "10%",
        },
        {
          name: "Status",
          selector: (row) => (
            <div
              className="relative job-search-label-section singleselect2  statusenroll hrp-table"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Select2
                ref={statusref}
                data={status.map((type) => ({
                  id: type.value,
                  text: type.label,
                  title:
                    type.value === "Block" ? "Contact with superadmin" : "", // Tooltip for Block status
                }))}
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                value={row.status}
                onSelect={(e) => handleUpdateJobStatus(e, row._id)}
                options={{
                  placeholder: "Select Status",
                  width: "100%",
                  theme: "classic",
                }}
                disabled={row.status === "Block"} // Disable Select2 if status is Block
                optionRenderer={(option) => (
                  <div title={option.title || ""}>{option.text}</div> // Use title for tooltip
                )}
              />
              {/* Status Dot */}
              <span
                className="status-dot"
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  borderRadius: "50%",
                  width: "8px",
                  height: "8px",
                  backgroundColor:
                    status.find((s) => s.value === row.status)?.color || "#000", // Apply color based on status
                }}
              />
            </div>
          ),
          sortable: true,
          width: "10%",
        },
        {
          name: "Posted On",
          selector: (row) => new Date(row.postedDate).toLocaleDateString(),
          sortable: true,
          width: "10%",
        },
        {
          name: "Actions",
          cell: (row) => {
            const {
              canEdit = false,
              canDelete = false,
              canView = false,
              canShare = false,
            } = row;
            return canEdit || canDelete || canView || canShare ? (
              <div className="flex gap-2 w-full item-center justify-center">
                {canEdit && (
                  <button
                    className="manage-edit-btn text-white px-6 py-2"
                    onClick={() => history.push(`./jobpost/${row._id}`)}
                  >
                    <FeatherIcon icon="edit-2" />
                  </button>
                )}
                {canDelete && (
                  <button
                    className="manage-delete-btn text-white px-6 py-2"
                    onClick={() => handleDelete(row._id)}
                  >
                    <FeatherIcon icon="trash" />
                  </button>
                )}
                {canView && (
                  <button
                    className="manage-view-btn text-white px-6 py-2"
                    onClick={() => history.push(`./jobview/${row._id}`)}
                  >
                    <FeatherIcon icon="eye" />
                  </button>
                )}
              </div>
            ) : (
              <div style={{ width: "100%", textAlign: "center" }}>—</div>
            );
          },

          width: "10%",
        },
      ]
    : [
        {
          name: "Job Title",
          selector: (row) => (
            <>
              <a
                href={`${domainpath}/jobs/${row.jobId}`}
                className="hover:underline"
                style={{ color: "#003366", fontSize: "14px", fontWeight: 600 }}
              >
                {row.jobTitle}
              </a>
              <br />
              {row.reportedBy
                ? `${row.reportedBy.name} (${row.reportedBy.email})`
                : ""}
            </>
          ),
          width: "",
          sortable: true,
        },
        {
          name: "Status",
          selector: (row) => (
            <div className="relative job-search-label-section singleselect2 statusenroll">
              <Select2
                data={status.map((type) => ({
                  id: type.value,
                  text: type.label,
                }))}
                className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                value={row.jobStatus}
                onSelect={(e) => handleUpdateJobStatus(e, row.jobId)}
                options={{
                  placeholder: "Select Status",
                  width: "100%",
                  theme: "classic",
                }}
              />
            </div>
          ),
          sortable: true,
          width: "",
        },
        {
          name: "Details",
          selector: (row) => row.details,
          sortable: true,
          width: "",
        },
        {
          name: "Reported Date",
          selector: (row) => new Date(row.reportedDate).toLocaleDateString(),
          sortable: true,
          width: "",
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="flex gap-1">
              <button
                className="manage-edit-btn text-white px-6 py-2"
                onClick={() => history.push(`./report/${row._id}`)}
              >
                <FeatherIcon icon="edit-2" />
              </button>
              <button
                className="manage-delete-btn text-white px-6 py-2"
                onClick={() => handleDelete(row.jobId)}
              >
                <FeatherIcon icon="trash" />
              </button>
            </div>
          ),
          width: "15%",
        },
      ];
  const handlePrint = () => {
    const printContent = document.getElementById("tableContent"); // Get the table content by ID or class
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printContent.innerHTML); // Insert table content into print window
    printWindow.document.write("</body></html>");
    printWindow.document.close(); // Close the document for printing
    printWindow.print(); // Trigger the print dialog
  };
  return (
    <div className="p-8 hrp-manage-job super-admin-dashboard">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8"></div>
      <div className="bg-white p-4 rounded-lg shadow">
        <input
          type="text"
          placeholder="Search Jobs"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ borderRadius: "50px", padding: "10px 20px" }}
          className="border rounded p-2 mb-4"
        />
        {searchTerm && (
          <button
            onClick={() => setSearchTerm("")} // Clear the search term
            title="Clear Search"
            style={{
              padding: "10px 20px",
              marginLeft: "20px",
            }}
            className="joblist-cardbtnapply"
          >
            Clear
          </button>
        )}
        <div className="flex gap-2 mb-4 justify-between">
          <div className="hrp-table-button-style">
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showMessages ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(true);
                setShowNotes(false);
                setPage(1); // Reset to the first page when changing views
              }}
            >
              Manage Jobs
            </button>
            <button
              className={`mt-2 px-4 py-2 hrp-ticket-btn ${
                showNotes ? "activebtn" : "inactive"
              } text-white`}
              onClick={() => {
                setShowMessages(false);
                setShowNotes(true);
                setPage(1); // Reset to the first page when changing views
              }}
            >
              Reported Jobs
            </button>
          </div>
          <div>
            <div className="flex gap-5 items-center">
              <div className="flex w-full">Sort By:</div>
              <div className="hrp-filtter-input-style">
                {" "}
                <div
                  className="relative job-search-label-section singleselect2 statusenroll hrp-table"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Select2
                    ref={statusref}
                    data={statusOptions.map((type) => ({
                      id: type.value,
                      text: type.label,
                      title:
                        type.value === "Block" ? "Contact with superadmin" : "", // Tooltip for Block status
                    }))}
                    className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                    value={statusFilter} // Adjust based on your actual `value` logic
                    onSelect={(e) => handlegetJobBystatus(e)}
                    options={{
                      placeholder: "Select Status",
                      width: "100%",
                      theme: "classic",
                    }}
                    disabled={status === "Block"} // Disable Select2 if status is Block
                    optionRenderer={(option) => (
                      <div title={option.title || ""}>{option.text}</div> // Tooltip for each option
                    )}
                  />
                  {/* Status Dot */}
                  {/* <span
                    className="status-dot"
                    style={{
                      position: "absolute",
                      left: "5px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      borderRadius: "50%",
                      width: "8px",
                      height: "8px",
                      backgroundColor:
                        statusOptions.find((s) => s.value === status)?.color ||
                        "#000", 
                    }}
                  /> */}
                </div>
              </div>

              <div className="flex gap-2">
                <div>
                  {" "}
                  <div className="custom-print-style" onClick={handlePrint}>
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.4999 6.41667H5.49992V2.75H16.4999V6.41667ZM16.4999 11.4583C16.7596 11.4583 16.9775 11.3703 17.1535 11.1943C17.3295 11.0183 17.4172 10.8008 17.4166 10.5417C17.416 10.2826 17.328 10.065 17.1526 9.889C16.9772 9.713 16.7596 9.625 16.4999 9.625C16.2402 9.625 16.0226 9.713 15.8473 9.889C15.6719 10.065 15.5839 10.2826 15.5833 10.5417C15.5826 10.8008 15.6706 11.0186 15.8473 11.1953C16.0239 11.3719 16.2414 11.4596 16.4999 11.4583ZM14.6666 17.4167V13.75H7.33325V17.4167H14.6666ZM16.4999 19.25H5.49992V15.5833H1.83325V10.0833C1.83325 9.30417 2.10061 8.65119 2.63534 8.12442C3.17006 7.59764 3.81936 7.33394 4.58325 7.33333H17.4166C18.1958 7.33333 18.849 7.59703 19.3764 8.12442C19.9038 8.65181 20.1672 9.30478 20.1666 10.0833V15.5833H16.4999V19.25Z"
                        fill="#FFA500"
                      />
                    </svg>
                  </div>
                  Print
                </div>
                <div>
                  <div className="flex justify-center">
                    {" "}
                    <div
                      className="custom-download-style"
                      onClick={handleDownload}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.1078 7.24695C8.23671 7.37569 8.41145 7.44801 8.59363 7.44801C8.77582 7.44801 8.95056 7.37569 9.07947 7.24695L10.6561 5.67028V13.1778C10.6561 13.3601 10.7286 13.535 10.8575 13.6639C10.9864 13.7928 11.1613 13.8653 11.3436 13.8653C11.526 13.8653 11.7008 13.7928 11.8298 13.6639C11.9587 13.535 12.0311 13.3601 12.0311 13.1778V5.67028L13.6078 7.24695C13.6707 7.31449 13.7466 7.36867 13.831 7.40625C13.9153 7.44382 14.0063 7.46403 14.0987 7.46566C14.191 7.46729 14.2827 7.4503 14.3683 7.41573C14.4539 7.38115 14.5316 7.32968 14.5969 7.2644C14.6622 7.19912 14.7137 7.12135 14.7482 7.03575C14.7828 6.95014 14.7998 6.85845 14.7982 6.76613C14.7965 6.67382 14.7763 6.58279 14.7388 6.49845C14.7012 6.41412 14.647 6.33822 14.5795 6.27528L11.8295 3.52528C11.7006 3.39653 11.5258 3.32422 11.3436 3.32422C11.1614 3.32422 10.9867 3.39653 10.8578 3.52528L8.1078 6.27528C7.97905 6.40419 7.90674 6.57893 7.90674 6.76111C7.90674 6.9433 7.97905 7.11804 8.1078 7.24695Z"
                          fill="#003366"
                        />
                        <path
                          d="M11.3438 18.6771C13.2888 18.6771 15.154 17.9045 16.5293 16.5292C17.9045 15.1539 18.6772 13.2887 18.6772 11.3438H15.2397C14.3752 11.3438 13.9435 11.3438 13.6749 11.6123C13.4063 11.8809 13.4063 12.3127 13.4063 13.1771C13.4063 13.7241 13.189 14.2487 12.8022 14.6355C12.4154 15.0223 11.8908 15.2396 11.3438 15.2396C10.7968 15.2396 10.2722 15.0223 9.88542 14.6355C9.49863 14.2487 9.28133 13.7241 9.28133 13.1771C9.28133 12.3127 9.28133 11.8809 9.01275 11.6123C8.74416 11.3438 8.31241 11.3438 7.448 11.3438H4.0105C4.0105 13.2887 4.78312 15.1539 6.15838 16.5292C7.53365 17.9045 9.39891 18.6771 11.3438 18.6771Z"
                          fill="#003366"
                        />
                      </svg>
                    </div>
                  </div>
                  Export
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <CustomDataTable
              columns={columns}
              data={filteredData}
              totalRows={totalRows}
              rowsPerPageOptions={[10, 25, 50]}
              defaultRowsPerPage={perPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerRowsChange}
              currentPage={page}
              handleEdit={handleEdit}
              handleView={handleView}
              handleDelete={handleDelete}
              handleShare={handleShare}
            />
          </>
        )}
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageJobs;
