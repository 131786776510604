import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import { jobSectors } from "../../helpers/Object";
import SearchableDropdown from "../../helpers/SearchableDropdown";
import { getUserData } from "../../helpers/utils";
import CustomToastContainer from "../Common/CustomToastContainer";
const Generateai = ({ onClose, onSave, data, jobid }) => {
  const [loading, setLoading] = useState(false);
  const User = getUserData();
  const [errors, setErrors] = useState({});
  const toastRef = useRef();
  const [formData, setFormData] = useState({
    positions: "1",
    jobDesignation: "",
    companyName: "",
    salary: "",
    payFrequency: "Annually",
    location: {
      type: "On-Site",
      city: "",
      area: "",
      pincode: "",
      address: "",
      lat: null,
      long: null,
    },
    jobType: "Full-Time",
    industry: "",
  });
  const inputRef = useRef(null);
  const inputRefs = useRef([]);
  const validateForm = () => {
    const newErrors = {};
    if (!formData.jobDesignation.trim()) {
      newErrors.jobDesignation = "Job Title is required.";
    }
    if (!formData.industry.trim()) {
      newErrors.industry = "Industry is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    // Clear the input fields when the component is mounted
    inputRefs.current.forEach((input) => {
      if (input) {
        input.value = "";
      }
    });
  }, []);

  const handleBlur = (index) => {
    const input = inputRefs.current[index];
    if (input && input.value !== "") {
      input.classList.add("has-content");
    } else {
      input.classList.remove("has-content");
    }
  };

  const [companyIndustry, setCompanyIndustry] = useState("");
  const [inputValue, setInputValue] = useState("");
  // useEffect(() => {
  //   setFormData({
  //     positions: data?.positions || "1",
  //     jobDesignation: data?.title || "",
  //     companyName: data?.company?.name || "",
  //     salary: data?.pay.maximum || "",
  //     payFrequency: data?.pay.rateType || "Annually", // Ensure "Monthly" is used here for consistency
  //     location: data?.location?.address || "",
  //     jobType: data?.pay.jobType || "Full-Time",
  //     industry: data?.industry || "",
  //   });
  //   console.log(jobid);

  //   setInputValue(data?.location?.address || "");
  //   if (User?._id && jobid == "") {
  //     const fetchCompanyDetails = async () => {
  //       try {
  //         const response = await api.getCompanybyEmp(User._id);
  //         if (response.status === 200) {
  //           const companyData = response.company;

  //           // Set form data with company details
  //           setFormData((prevData) => ({
  //             ...prevData,
  //             companyName: companyData.name || prevData.companyName,
  //             industry: companyData.industry || prevData.industry,
  //             location: companyData.address || prevData.location,
  //           }));
  //           setInputValue(companyData.address || "");

  //           setCompanyIndustry(companyData.industry); // Set industry state separately
  //         } else {
  //           console.error("Company details not found");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching company details:", error);
  //       }
  //     };

  //     fetchCompanyDetails();
  //   }
  // }, [User?._id]);

  useEffect(() => {
    if (User?._id && !formData.location.address && !jobid) {
      const fetchCompanyDetails = async () => {
        try {
          const response = await api.getCompanybyEmpDefault(User._id);
          if (response.status === 200) {
            const companyData = response.defaultCompany;

            // Send the company address to Google Geocoding API
            const geocodeAddress = async (address) => {
              try {
                const geocodeResponse = await fetch(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    address
                  )}&key=AIzaSyAdieoYjOgCnstLLpHHCZmcMcAwQjeeLjg`
                );
                const geocodeData = await geocodeResponse.json();

                if (
                  geocodeData.status === "OK" &&
                  geocodeData.results.length > 0
                ) {
                  const result = geocodeData.results[0];
                  const addressComponents = result.address_components;
                  const geometry = result.geometry;

                  // Parse address components
                  const locationData = {
                    type: "On-Site",
                    address: result.formatted_address || address,
                    city: "",
                    area: "",
                    pincode: "",
                    lat: geometry.location.lat,
                    long: geometry.location.lng,
                  };

                  addressComponents.forEach((component) => {
                    const types = component.types;
                    if (
                      types.includes("locality") ||
                      types.includes("postal_town")
                    ) {
                      locationData.city = component.long_name;
                    }
                    if (
                      types.includes("sublocality") ||
                      types.includes("sublocality_level_1") ||
                      types.includes("neighborhood") ||
                      types.includes("political")
                    ) {
                      locationData.area = component.long_name;
                    }
                    if (types.includes("postal_code")) {
                      locationData.pincode = component.long_name;
                    }
                  });

                  return locationData;
                } else {
                  console.error("Geocoding failed:", geocodeData.status);
                  return null;
                }
              } catch (error) {
                console.error("Error with Geocoding API:", error);
                return null;
              }
            };

            const locationData = await geocodeAddress(companyData.address);

            if (locationData) {
              setFormData((prevData) => ({
                ...prevData,
                companyName: companyData.name || prevData.companyName,
                industry: companyData.industry || prevData.industry,
                location: locationData,
              }));

              setInputValue(locationData.address);
              setCompanyIndustry(companyData.industry);
            } else {
              console.warn(
                "Failed to fetch location data for the company address."
              );
            }
          } else {
            console.error("Company details not found");
          }
        } catch (error) {
          console.error("Error fetching company details:", error);
        }
      };

      fetchCompanyDetails();
    }
  }, [User?._id, formData.location.address, jobid]);

  useEffect(() => {
    setFormData((prevData) => ({
      positions: data?.positions || "1",
      jobDesignation: data?.title || "",
      companyName: data?.company?.name || "",
      salary: data?.pay.maximum || "",
      payFrequency: data?.pay.rateType || "Annually",
      location: {
        ...prevData.location,
        type: data?.location?.type || prevData.location.type,
        address: data?.location?.address || prevData.location.address,
        city: data?.location?.city || prevData.location.city,
        area: data?.location?.area || prevData.location.area,
        pincode: data?.location?.pincode || prevData.location.pincode,
        lat: data?.location?.lat || prevData.location.lat,
        long: data?.location?.long || prevData.location.long,
      },
      jobType: data?.pay.jobType || "Full-Time",
      industry: data?.industry || "",
    }));
    setInputValue(data?.location?.address || "");
  }, [data]);
  useEffect(() => {
    // Set initial input value
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: "uk" },
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.warn("Autocomplete returned place without geometry.");
          return;
        }

        const addressComponents = place.address_components || [];

        // Parse the address components
        const addressData = {
          address: place.formatted_address || "",
          city: "",
          area: "",
          pincode: "",
          lat: place.geometry.location.lat(), // Latitude
          long: place.geometry.location.lng(), // Longitude
        };

        // Update address fields based on address_components
        addressComponents.forEach((component) => {
          const types = component.types;

          if (types.includes("locality") || types.includes("postal_town")) {
            addressData.city = component.long_name;
          }
          if (
            types.includes("sublocality") ||
            types.includes("sublocality_level_1") ||
            types.includes("neighborhood") ||
            types.includes("political")
          ) {
            addressData.area = component.long_name;
          }
          if (types.includes("postal_code")) {
            addressData.pincode = component.long_name;
          }
        });

        // Fallback logic for city and area if they are empty
        if (!addressData.city && place.formatted_address) {
          const addressParts = place.formatted_address.split(",");
          addressData.city =
            addressParts[addressParts.length - 2]?.trim() || "";
        }
        if (!addressData.area && place.formatted_address) {
          const addressParts = place.formatted_address.split(",");
          addressData.area =
            addressParts[addressParts.length - 3]?.trim() || "";
        }

        // Log the final parsed address data
        console.log("Address Data:", addressData);

        // Update formData with parsed location data
        setFormData((prevFormData) => ({
          ...prevFormData,
          location: {
            ...prevFormData.location,
            ...addressData,
          },
        }));

        setInputValue(place.formatted_address);
      });

      // Cleanup autocomplete listeners on component unmount
      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "location") {
      setInputValue(value);
      setFormData((prevData) => ({ ...prevData, location: value }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);

    try {
      // Debug: Log the initial value of applicationDeadline
      //console.log("Initial applicationDeadline:", formData.applicationDeadline);

      // Check if applicationDeadline is a valid date string
      let deadline = formData.applicationDeadline
        ? new Date(formData.applicationDeadline)
        : new Date(); // Fallback to current date if undefined

      // Ensure applicationDeadline is valid and handle fallback
      if (isNaN(deadline.getTime())) {
        console.warn(
          "Invalid applicationDeadline, falling back to current date."
        );
        deadline = new Date(); // Fallback to current date if invalid
      }
      formData.employerid = User != "" ? User?._id : "";
      // Convert to ISO string if valid
      const formDataWithDates = {
        ...formData,
        applicationDeadline: deadline.toISOString(),
        jobid: data?._id,
      };

      const response = await api.generateai(formDataWithDates);
      if (response.status === 201) {
        onSave(response?.jsonObject);
        onClose("");
      } else {
        toastRef.current.addToast(
          "Well, that was unexpected. Shall we give it another shot?",
          "error"
        );
      }
    } catch (err) {
      console.error("Error submitting form:", err);
      toastRef.current.addToast(
        err.response ? err.response.data : err.message,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };
  const selectdays = [
    { name: "Annually", value: "Annually " },
    { name: "Monthly", value: "Monthly" },
    { name: "Weekly", value: "Weekly" },
    { name: "Per Day", value: "Per Day" },
    { name: "Hourly Rate", value: "Hourly" },
    { name: "Fixed", value: "Fixed" },
    { name: "Range", value: "Range" },
    { name: "Per Project", value: "Per Project" },
  ];
  const jobtype = [
    { name: "Full-Time", value: "Full-Time" },
    { name: "Part-Time", value: "Part-Time" },
    { name: "Permanent", value: "Permanent" },
    { name: "Temporary contract", value: "Temporary contract" },
    { name: "Internship", value: "Internship" },
    { name: "Freelance", value: "Freelance" },
  ];
  const handleDropdownChange = (selectedItem, fieldName) => {
    if (selectedItem) {
      // Check if selectedItem is an object with value property or a plain string
      const value =
        typeof selectedItem === "string" ? selectedItem : selectedItem.value;
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value, // Use the selected value for updating formData
      }));
    } else {
      console.warn(`No value found for selectedItem: ${selectedItem}`);
    }
  };
  return (
    <div className="flex justify-center items-center	 h-screen m-auto 2xl:p-20 py-20 job-information-popup hrp-generateai-page">
      <div className="flex ">
        <div
          className="form_upto jobapply-width gen-jobapply-width m-3 loginDiv"
          id="step_1"
        >
          <div className="bg-white shadow-md rounded px-6 pt-4 pb-6 mb-4 generateai-page">
            <div className="hrp-heading-job">
              <h1 className="hrp-heading-size text-center hrp-About">
                Job Specification
              </h1>
            </div>
            <form onSubmit={handleSubmit} className="md:p-5 text-left">
              <ul
                className="list-disc pl-5 space-y-3"
                style={{ width: "90%", margin: "0 auto" }}
              >
                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Job Title<span className="text-red-500">*</span>:
                  </strong>
                  <div className="col-3 input-effect">
                    <input
                      type="text"
                      id="jobDesignation"
                      name="jobDesignation"
                      value={formData.jobDesignation}
                      onChange={handleChange}
                      required
                      placeholder="Designation"
                      className={`effect-2 ${
                        errors.jobDesignation ? "border-red-500" : ""
                      }`}
                    />
                    <span className="focus-border"></span>
                    {errors.jobDesignation && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.jobDesignation}
                      </p>
                    )}
                  </div>
                </li>

                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Positions Available<span className="text-red-500">*</span>:
                  </strong>
                  <div className="col-3 input-effect">
                    <input
                      type="number"
                      id="positions"
                      name="positions"
                      value={formData.positions}
                      onChange={handleChange}
                      required
                      placeholder="Number"
                      className="effect-2"
                    />
                    <span className="focus-border"></span>
                  </div>
                </li>

                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Company<span className="text-red-500">*</span>:
                  </strong>
                  <div className="col-3 input-effect">
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                      placeholder="Company Name"
                      className="effect-2"
                    />
                    <span className="focus-border"></span>
                  </div>
                </li>

                <li className="md:flex items-center industry">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Industry<span className="text-red-500">*</span>:
                  </strong>
                  <SearchableDropdown
                    options={jobSectors.map((sector) => ({
                      value: sector.value,
                      label: sector.label,
                    }))}
                    multiple={false}
                    name="industry"
                    required
                    value={jobSectors.find(
                      (sector) => sector.value === formData.industry
                    )}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "industry")
                    }
                  />
                </li>
                {errors.industry && (
                  <p className="text-red-500 text-sm mt-1">{errors.industry}</p>
                )}
                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Salary Offered<span className="text-red-500">*</span>:
                  </strong>
                  <div className="col-3 input-effect">
                    <input
                      type="number"
                      id="salary"
                      name="salary"
                      value={formData.salary}
                      onChange={handleChange}
                      required
                      placeholder="Salary Amount"
                      className="effect-2"
                    />
                    <span className="focus-border"></span>
                  </div>
                  <span className="ml-2">,</span>
                  <SearchableDropdown
                    options={selectdays.map((day) => ({
                      label: day.name,
                      value: day.value,
                    }))}
                    multiple={false}
                    name="payFrequency"
                    required
                    value={{
                      value: formData.payFrequency,
                      label: formData.payFrequency,
                    }}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "payFrequency")
                    }
                  />
                </li>

                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Job Location<span className="text-red-500">*</span>:
                  </strong>
                  <div className="col-3 input-effect">
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={inputValue}
                      onChange={handleChange}
                      required
                      ref={inputRef}
                      placeholder="Location"
                      className="effect-2"
                    />
                    <span className="focus-border"></span>
                  </div>
                </li>

                <li className="md:flex items-center">
                  <strong className="text-lg flex-shrink-0 w-1/4">
                    Employment Status:
                  </strong>
                  <SearchableDropdown
                    options={jobtype.map((type) => ({
                      label: type.name,
                      value: type.value,
                    }))}
                    name="jobType"
                    value={{
                      value: formData.jobType,
                      label:
                        jobtype.find((type) => type.value === formData.jobType)
                          ?.name || formData.jobType,
                    }}
                    onChange={(selectedItem) =>
                      handleDropdownChange(selectedItem, "jobType")
                    }
                    className="effect-2"
                  />
                </li>
              </ul>

              <div className="text-center mt-5">
                <button type="submit" className="px-5 py-3 btnpostjob text-xl">
                  Generate Posting
                </button>
              </div>
            </form>
          </div>
        </div>
        <button
          className="text-2xl flex mx-auto justify-center items-center bg-white rounded-2xl w-6 h-6 top-6 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}{" "}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Generateai;
