import React, { useState, useRef, useEffect } from "react";
import { getUserData } from "../../helpers/utils";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";
import axios from "axios";
import logoimage from "../../image/company-logo-transparent-png-19.png";
import { useHistory } from "react-router-dom";
import { usePopup } from "../Common/PopupContext";
import CompanySettings from "../popup/Profilepagepopup/CompanySettings.js";
const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const Companysettings = () => {
  const history = useHistory();
  const user = getUserData();
  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [companies, setCompanies] = useState([]);
  const toastRef = useRef(); // Reference to the address input field for Google Autocomplete
  const [loading, setLoading] = useState(false);
  // Will store full address details like city, area, lat, long
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [companyData, setCompanyData] = useState([]);
  const truncateText = (text, wordLimit) => {
    if (!text) return ""; // If no description is provided
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const handleSetDefaultCompany = async (company_id) => {
    try {
      const response = await axios.put(
        `${domainpath}/api/company/${company_id}/set-default`,
        {
          user_id: user?._id,
        }
      );
      if (response.status === 200) {
        setSelectedCompany(company_id);
        toastRef.current.addToast(
          "Default company updated successfully",
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 600);
      } else {
        toastRef.current.addToast("Failed to update default company", "error");
      }
    } catch (error) {
      toastRef.current.addToast("Error updating default company", "error");
    }
  };

  const CompanyCard = ({
    company,
    company_id,
    company_name,
    company_logo,
    industry,
    description,
    address,
    selected,
    handleEditCompany,
    onSelect,
    onDelete,
  }) => {
    const [hovered, setHovered] = useState(false);

    return (
      <div
        className={`company-card md:flex items-center justify-evenly p-4 mb-4 border-2 rounded md:rounded-full hrp-card-border ${
          selected ? "border-yellow-500" : "border-blue-500"
        }`}
        style={{
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
      >
        <div className="w-24">
          <img
            src={
              company_logo?.startsWith("http")
                ? company_logo
                : company_logo?.includes("uploads")
                ? `${domainpath}${company_logo}`
                : `${domainpath}/uploads/${company_logo}`
            }
            alt={`${company_name} Logo`}
            onClick={() => history.push(`/companies/${company_name}`)}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = logoimage; // Replace with a default logo
            }}
            className="w-full h-24 rounded-full"
          />
        </div>

        {/* Company Details */}
        <div className="flex-grow ml-6">
          <h2
            className="md:text-lg font-bold text-black"
            style={{ color: "#003366" }}
          >
            {company_name}
          </h2>
          <p className="text-sm text-gray-600">{industry || "N/A"}</p>
          <p className="text-xs text-gray-500">
            {/* {truncateText(description, 20)} */}
            {address}
          </p>
        </div>

        {/* Controls */}
        <div className="flex items-center justify-end space-x-4 company-icons">
          {/* Checkbox */}
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="relative mt-1"
          >
            <input
              type="checkbox"
              checked={selected}
              onChange={() => handleSetDefaultCompany(company._id)}
              className="form-checkbox h-5 w-5 text-yellow-500 border-2 cursor-pointer"
            />
            {hovered && (
              <div
                className="absolute mt-2 p-2 text-xs text-white bg-gray-800 rounded checkbox-tooltip
              "
              >
                Default Company
              </div>
            )}
          </div>

          <svg
            onClick={() => {
              handleEditCompany(company);
            }}
            className="cursor-pointer"
            width={"16px"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#003366"
          >
            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
          </svg>

          {/* Delete Button */}
          <div className="cursor-pointer" onClick={() => onDelete(company_id)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.333 7.083a1.25 1.25 0 011.25 1.25c0 .347-.14.68-.39.93-.25.25-.583.39-.93.39h-1.417l-1.33 18.517a2.5 2.5 0 01-2.474 2.303H11.234a2.5 2.5 0 01-2.474-2.303L7.084 9.916H5.667a1.25 1.25 0 110-2.5h22.666zM19.833 2.833a1.25 1.25 0 110 2.5h-5.667a1.25 1.25 0 110-2.5h5.667z"
                fill="#FF0000"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  // Fetch company details on component mount
  useEffect(() => {
    setLoading(true);
    const fetchCompanyDetails = async () => {
      try {
        const response = await api.getCompanybyEmp(user?._id);
        if (response.status === 200) {
          const companyData = response.company;
          setCompanies(companyData);
          setLoading(false);
        } else {
          toastRef.current.addToast("Company details not found", "error");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        toastRef.current.addToast("Error fetching company details", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [user._id]);

  const handleDelete = async (company_id) => {
    try {
      // Call the delete API
      const response = await axios.delete(
        `${domainpath}/api/company/${company_id}`
      );

      if (response.status === 200) {
        // Remove the company from the local state
        setCompanies((prevCompanies) =>
          prevCompanies.filter((company) => company._id !== company_id)
        );
        console.log("Deleted company with ID:", company_id);
        toastRef.current.addToast("Company deleted successfully", "success");
      } else {
        toastRef.current.addToast("Failed to delete company", "error");
      }
    } catch (error) {
      console.error("Error deleting company:", error);
      toastRef.current.addToast("Error deleting company", "error");
    }
  };
  const handleSelect = (company) => {
    setSelectedCompany(company?._id);
    setCompanyData(company);
  };
  const handleClosePopup = (message) => {
    //console.log(message);
    //setPopupComponent(null);
    openPopup();
  };
  const handleSaveCompany = (formData) => {
    // setCompanyData([...companyData, formData]);
    window.location.reload();
  };
  // const handleAddCompany = (formData) => {
  //   const popup = (
  //     <CompanySettings
  //       onSave={(formData) => handleSaveCompany(formData)}
  //       onClose={() => handleClosePopup("Popup closed")}
  //     />
  //   );
  //   //setPopupComponent(popup);
  //   openPopup(popup);
  // };
  const handleAddCompany = () => {
    openPopup(
      <CompanySettings
        onSave={(data) => handleSaveCompany(data)}
        onClose={() => handleClosePopup()}
      />
    );
  };

  const handleEditCompany = (company) => {
    openPopup(
      <CompanySettings
        initialValues={company} // Pass the company details for editing
        onSave={(updatedData) => handleUpdateCompany(company._id, updatedData)}
        onClose={() => handleClosePopup()}
      />
    );
  };

  const handleUpdateCompany = (id, data) => {
    // console.log("Updated company ID:", id, "with data:", data);
    window.location.reload();
  };
  return (
    <>
      {" "}
      <div className="xl:w-1/2 md:w-full">
        <div className="container mx-auto p-4">
          <div className="">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-bold hrp-profile-selected-active text-center ml-16">
                Your Active Comopanies
              </h1>
              <button
                onClick={() => handleAddCompany()}
                className="joblist-btnclear text-white px-4 py-2 rounded mt-4 mr-16"
              >
                + Add Company
              </button>
            </div>

            <div className="p-8 account-settings-main">
              {companies?.length > 0 ? (
                companies?.map((company) => (
                  // console.log(company?.defaultCompany),
                  <CompanyCard
                    key={company._id}
                    company={company}
                    company_id={company._id}
                    company_name={company.name}
                    address={company.address}
                    company_logo={company.image}
                    industry={company.industry}
                    description={company.description}
                    handleEditCompany={() => handleEditCompany(company)}
                    selected={company?.defaultCompany}
                    onSelect={() => handleSelect(company)}
                    onDelete={() => handleDelete(company._id)}
                  />
                ))
              ) : (
                <p>No companies available.</p>
              )}

              {loading && (
                <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
                  <span className="RoadmapSpinner"></span>
                </div>
              )}
              <CustomToastContainer ref={toastRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Companysettings;
